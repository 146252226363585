import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Table,
  Form,
  CardTitle,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip,
  Spinner,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import classnames from "classnames"
import Select from "react-select"
import img3 from "../../assets/images/apibanner.svg"
import img2 from "../../assets/images/card.df0f73fc.svg"

import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

import TableContainer from "../../components/Common/TableContainer"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
//Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import toastr, { error } from "toastr"
import "toastr/build/toastr.min.css"
import axios from "axios"
import { EditSharp, InfoOutlined } from "@mui/icons-material"
const OrganizationSetting = props => {
  //meta title
  document.title = `Organization | ${process.env.REACT_APP_NAME}`

  const [activeTab, setActiveTab] = useState("1")
  const [BreaksNo, setNoofBreaks] = useState(0)
  const [orgName, setOrgName] = useState("")
  const [orgId, setOrgid] = useState("")
  const [orgCat, setOrgCat] = useState("")
  const [GoogleAPIKey, setGoogleAPIKey] = useState("")
  const [selectorgCat, setSelectOrgCat] = useState({ label: "", value: "" })
  const [spinner, setSpinner] = useState(false)
  const [showPlaceholder, setShowPlaceHolder] = useState(false)
  const [departmentsList,setDepartmentList] = useState([]);
  const [mockDataOther,setMockDataOther] = useState([]);
  const [addDepartmentModal,setaddDepartmentModal] = useState(false);
  const [editModeDepartment, seteditModeDepartment] = useState(false)
  const [editKeyMode, setEditKeyMode] = useState(false)
  const [singleDept, setSingleDept] = useState({
    id : '',
    name : '',
    location: {label: '',value:''}
  })
  const [isLoading,setIsLoading] = useState(false);
  const fetchDepartment = async()=>{
    let local = localStorage.getItem('authUser');
    let mapsing = JSON.parse(local);
    let org = mapsing.map((e)=>e.org_id);
     var formdata = new FormData();
     formdata.append('method', 'get-department-by-admin');
     formdata.append('org_id', org);
     var requestOptions = {
       method: 'POST',
       body: formdata,
       redirect: 'follow',
     };
     
     await fetch(process.env.REACT_APP_LEAVE_API_URL +'/departments.php', requestOptions)
     .then(response => response.json())
     .then(result => {
         setDepartmentList(result);
   
       })
       .catch(error => console.log('error', error));
    
 }
 const editDepartment = (item) =>{
  
  setSingleDept({
    name: item.name,
    id : item.id,
    location : {label: item.location , value : item.location}
  })
  
  setaddDepartmentModal(true);
  seteditModeDepartment(true);
 }
 const fetchCompaineName = async () => {
  let localVar = localStorage.getItem("authUser")
  let localArray = JSON.parse(localVar)
  let emp = localArray.map(e => e.user_id)
  let org = localArray.map(e => e.org_id)
  let role = localArray.map(e => e.role)
  var formdata = new FormData();
  formdata.append('method', 'fetch_company');
  formdata.append('role', role);
  formdata.append('emp_id', emp);
  formdata.append('org_id', org);
  var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow',
  };

  await fetch(process.env.REACT_APP_LEAVE_API_URL+'/company.php', requestOptions)
    .then(response => response.json())
    .then(result => {
      console.warn(result);
      if (result.length !== 0) {
        result.map(e => {
          let val = {
            value: e.location,
            label: e.location,
          };
           mockDataOther.push(val);
        });
      }
    })
    .catch(error => console.log('error', error));
};
  const getData = () => {
    setShowPlaceHolder(true)
    let local = localStorage.getItem("authUser")
    let json = JSON.parse(local)

    json.map(e => {
      var formdata = new FormData()
      formdata.append("method", "get-organization-by-id")
      formdata.append("emp_id", e.user_id)
      formdata.append("org_id", e.org_id)

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      }

      fetch(process.env.REACT_APP_LEAVE_API_URL+"/company.php", requestOptions)
        .then(response => response.json())
        .then(result => {
          setShowPlaceHolder(false)
          setOrgName(result.company.org_title)
          setGoogleAPIKey(result.company.google_api_key)
          setNoofBreaks(result.company.no_employees)
          setOrgCat(result.company.category)
          setSelectOrgCat({
            label: result.company.category,
            value: result.company.category,
          })
          setOrgid(result.company.id)
        })
        .catch(error => console.log("error", error))
    })
  }
  const deleteDepartment = (passingID) =>{
    Swal.fire({
      icon: 'warning',
      title:'Confirmation',
      text:'Are you sure to want to delete this department',
      showCancelButton:true,
      backdrop:'static',
      cancelButtonColor:'red',
      cancelButtonText: "No, I don't",
      confirmButtonColor:'green',
      confirmButtonText:'Yes, I want'
    }).then((is)=>{
      if(is.isConfirmed){
            let form = new FormData();
            form.append('method','delete-department-by-admin')
            form.append('delete_id',passingID)
            axios.post(process.env.REACT_APP_LEAVE_API_URL+ '/departments.php', form).then((res)=>{
              if(res.data == 'success'){
                toastr.success('Department is successfully deleted');
                fetchDepartment();
              }else{
                toastr.error('Error while deleting department. Please try again');
              }
            }).catch((err)=>console.log(err))
      }else{
        Swal.close()
      }
    })
  }
  useEffect(() => {
    getData()
    fetchDepartment()
    fetchCompaineName()
    
  }, [])
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  const NoofBreaks = [
    { label: "1-10", value: "1-10" },
    { label: "11-20", value: "11-20" },
    { label: "30-40+", value: "30-40" },
    { label: "51-100+", value: "51-100+" },
    { label: "100+", value: "100+" },
  ]
  const mockData = [
    { label: "Advertising", value: "Advertising" }, // set default checked for render option item
    { label: "Agriculture", value: "Agriculture" },
    { label: "Automative", value: "Automative" },
    { label: "Construction", value: "Construction" },
    { label: "Consultancy", value: "Consultancy" },
    { label: "Education", value: "Education" },
    { label: "Field Services", value: "Field Services" },
    { label: "Finance", value: "Finance" },
    { label: "Food & Beverages", value: "Food & Beverages" },
    { label: "Government", value: "Government" },
    { label: "Health Care", value: "Health Care" },
    { label: "Hospitality", value: "Hospitality" },
    { label: "Legal", value: "Legal" },
    { label: "Leisure & Entertainment", value: "Leisure & Entertainment" },
    { label: "Logistics", value: "Logistics" },
    { label: "Manufacturing", value: "Manufacturing" },
    { label: "No-Profit & Charity", value: "Non-Profit & Charity" },
    { label: "Oil & Gas", value: "Oil & Gas" },
    { label: "Retail", value: "Retail" },
    { label: "Retail", value: "Retail" },
    { label: "Software or Internet", value: "Software or Internet" },
    { label: "Staffing", value: "Staffing" },
    { label: "Telecommunication", value: "Telecommunication" },
    { label: "Transportation", value: "Transportation" },
    { label: "Other", value: "Other" },
  ]

  const onPress = () => {
    setSpinner(true)
    let form = new FormData()
    form.append("org_id", orgId)
    form.append("org_title", orgName)
    form.append("org_cat", selectorgCat.value)
    form.append("noofEmployees", BreaksNo)
    form.append("method", "update-organization-data")
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }

    fetch(process.env.REACT_APP_LEAVE_API_URL+"/company.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.message == "success") {
          Swal.fire("Organization Data is successfully updated")
          let local = localStorage.getItem("authUser")
          let json = JSON.parse(local)
          console.log(json)
          const newJson = json.map(item => {
            if (item.org_id == orgId) {
              return {
                ...item,
                org_title: orgName,
              }
            }
            return item
          })
          console.log(newJson)
          localStorage.setItem("authUser", JSON.stringify(newJson))
          getData()
          setSpinner(false)
        } else {
          Swal.fire(
            "Server Error",
            "Error Organization Data is successfully updated"
          )
          setSpinner(false)
        }
      })
      .catch(error => console.log("error", error))
  }
  const departmentsColumn = useMemo(
    () => [
     
      {
        Header: "Department Name",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return (
            <div>
              <p>
                {cellProps.value}
              </p>
            </div>
          )
        },
      },
      {
        Header: "Location For",
        accessor: "location",
        filterable: true,
        Cell: cellProps => {
          return (
            <div>
              <p>
                {cellProps.value}
              </p>
            </div>
          )
        },
      },
      {
        Header: "Actions",
        accessor: "",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top" title="Edit">
              <Link to={`#`} 
                 onClick={()=>editDepartment(cellProps.cell.row.original)}
                   className="btn btn-sm btn-soft-primary">
                    
                  <i className="mdi mdi-eye-outline" id="viewtooltip"></i>
                </Link>
              </li>
              <UncontrolledTooltip placement="top" target="viewtooltip">
                Edit
              </UncontrolledTooltip>
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    // const jobData = cellProps.row.original;
                    deleteDepartment(cellProps.cell.row.original.id);
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          
          )
        },
      },
    
    ],
    []
  )
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="6">
              <Nav tabs className="nav-tabs-custom" role="tablist">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggleTab("1")
                    }}
                  >
                    Organization
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggleTab("2")
                    }}
                  >
                    Subscription
                  </NavLink>
                </NavItem> */}
               <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggleTab("2")
                    }}
                  >
                    Departments
                  </NavLink>
                </NavItem>
                 <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "4",
                    })}
                    onClick={() => {
                      toggleTab("4")
                    }}
                  >
                    Settings
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>

          <Row className="pt-3">
            <Col lg="12">
              <TabContent activeTab={activeTab} className="p-3">
                <TabPane tabId="1">
                 {
                  showPlaceholder == true ? 
                  <Row>
                  <Col xl="9">
                    <Card>
                      <CardBody>
                        <Row>
                          <Col xl="6" style={{ paddingBottom: 20 }}>
                            {/* <div className="d-sm-flex flex-wrap"> */}
                            <Skeleton count={1} height={20} width={200} />
                            {/* </div> */}
                          </Col>
                        </Row>
                        <Form>
                          <Row className="mb-4">
                          <Label
                              
                              className="col-sm-3 col-form-label"
                            >
                             <Skeleton  count={1} height={20} />
                            </Label>

                            <Col sm={9}>
                              <Skeleton count={1} height={40} />
                            </Col>
                          </Row>
                          <Row className="mb-4">
                          <Label
                              
                              className="col-sm-3 col-form-label"
                            >
                             <Skeleton  count={1} height={20} />
                            </Label>

                            <Col sm={9}>
                              <Skeleton count={1} height={40} />
                            </Col>
                          </Row>
                          <Row className="mb-4">
                          <Label
                              
                              className="col-sm-3 col-form-label"
                            >
                             <Skeleton  count={1} height={20} />
                            </Label>

                            <Col sm={9}>
                              <Skeleton count={1} height={40} width={400} />
                            </Col>
                          </Row>

                          
                        </Form>
                        <Row className="float-end mb-2">
                          <Col>
                            <div>
                            
                                <Skeleton width={200} className="w-wd" count={1} height={40} />
                            
                            </div>
                          </Col>
                        </Row>
                       
                       
                      </CardBody>
                    </Card>
                  </Col>
                </Row>: 
                  <Row>
                    <Col xl="9">
                      <Card>
                        <CardBody>
                          <Row>
                            <Col xl="6">
                              <div className="d-sm-flex flex-wrap">
                                <h4 className="card-title mb-4">
                                  Organization Information
                                </h4>
                              </div>
                            </Col>
                          </Row>
                          <Form>
                            <Row className="mb-4">
                              <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                              >
                                Organization name
                              </Label>
                              <Col sm={9}>
                                <Input
                                  type="text"
                                  value={orgName}
                                  onChange={e => setOrgName(e.target.value)}
                                  className="form-control"
                                  id="horizontal-firstname-Input"
                                  placeholder="organization name"
                                />
                              </Col>
                            </Row>
                            {/* <Row className="mb-4">
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                Country 
                              </Label>
                              <Col sm={9}>
                                <Select
                                  value="Select"
                                  options={optionGroup}
                                  className="select2-selection"
                                  placeholder="Select country or region"
                                />
                              </Col>
                            </Row> */}
                            <Row className="mb-4">
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                Organization Category
                              </Label>
                              <Col sm={9}>
                                <Select
                                  value={selectorgCat}
                                  options={mockData}
                                  onChange={e => setSelectOrgCat(e)}
                                  className="select2-selection"
                                  placeholder="Select Category"
                                />
                              </Col>
                            </Row>
                           
                            <Row className="mb-4">
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                No of Employees
                              </Label>
                              <Col sm={9} style={{ paddingTop: 10 }}>
                                {NoofBreaks.map((item, index) => (
                                  <span
                                    className="col-md-1"
                                    key={index}
                                    onClick={() => setNoofBreaks(item.value)}
                                    style={{
                                      background:
                                        BreaksNo == item.value
                                          ? "#8fe1ff"
                                          : "white",

                                      padding: 10, // Add padding for better appearance
                                      borderWidth: 1,
                                      borderStyle: "solid",
                                      textAlign: "center",
                                      cursor: "pointer",
                                      borderColor: "#8fe1ff",
                                    }}
                                  >
                                    {item.label}
                                  </span>
                                ))}
                              </Col>
                            </Row>
                          </Form>
                          <br />
                          {/* <Row>
                            <Col xl="6">
                              <div className="d-sm-flex flex-wrap">
                                <h4 className="card-title mb-4">
                                  REPORTING CURRENCY
                                </h4>
                              </div>
                            </Col>
                          </Row>
                          <Form>
                            <Row className="mb-4">
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                Currency
                              </Label>
                              <Col sm={9}>
                                <Select
                                  value="Select"
                                  options={optionGroup}
                                  className="select2-selection"
                                  placeholder="currency"
                                />
                              </Col>
                            </Row>
                          </Form> */}

                          <Row className="float-end mb-2">
                            <Col>
                              <div>
                                {spinner == true ? (
                                  <Spinner size={"md"} />
                                ) : (
                                  <Button
                                    type="submit"
                                    color="primary"
                                    className="w-md"
                                    onClick={() => onPress()}
                                  >
                                    Save Changes
                                  </Button>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                 }

                </TabPane>
                <TabPane tabId="2">
                <div className="mb-3">
                              <Button
                                type="button"
                                color="primary"
                                className="w-md"
                                onClick={() => setaddDepartmentModal(true)}
                              >
                                Add Department
                              </Button>
                            </div>
                      <TableContainer

                        columns={departmentsColumn}
                        data={departmentsList}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        customPageSize={10}
                      />
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col xl="6">
                      <Card>
                        <CardBody>
                          <CardTitle>Payment Method</CardTitle>

                          <div className="product-img position-relative">
                            <img
                              src={img2}
                              alt=""
                              className="img-fluid mx-auto d-block"
                            />
                          </div>
                          <div className="text-muted mb-3 text-center">
                            Add your payment details to enable <br />
                            recurring payments and worry about one thing less
                          </div>
                          <div className="text-center">
                            <Button type="submit" className="btn btn-warning">
                              Add payment method
                            </Button>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xl="6">
                      <Card>
                        <CardBody>
                          <Row>
                            <Col xl="6">
                              <div className="d-sm-flex flex-wrap">
                                <h4 className="card-title mb-4">
                                  BILLING INFO
                                </h4>
                              </div>
                            </Col>
                            <Col xl="6">
                              <div className="float-end">
                                <i
                                  className="bx bx-edit-alt"
                                  style={{ fontSize: "2em" }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <h5>YOUR ORGANIZATIONS</h5>
                  <Card className="mt-4">
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <thead className="table-light">
                            <tr>
                              <th>Organization name</th>
                              <th>Plan</th>
                              <th>Next Invoice</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">Unihandle</th>
                              <td>Free</td>
                              <td>$0.00</td>
                              <td>Manage</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </TabPane>
                <TabPane tabId="4">
               
                  <h5>Settings</h5>
                  <Card className="mt-3 pt-3">
                    <CardBody>  
                             <Form 
                             onSubmit={(e)=>{
                              e.preventDefault();
                              if(e.target.elements.google_api.value ==  ''){
                                toastr.error('Google API key is required');
                                return false;
                              }else{
                                let org = JSON.parse(localStorage.getItem('authUser')).map((e)=>e.org_id)
                                let form = new FormData();
                                form.append('method', 'update_organization_google_key');
                                form.append('org_id', org);
                                form.append('key_val', e.target.elements.google_api.value);
                                axios.post(process.env.REACT_APP_LEAVE_API_URL + '/company.php' , form).then((res)=>{
                                  if(res.data == 'success'){
                                    toastr.success('Organization Google API KEY is update');
                                    setEditKeyMode(false)
                                  }else{
                                    toastr.error('Organization Google API KEY not updated');
                                  }
                                }).catch((er)=>{
                                  console.log(er)
                                })
                              }
                             }}
                             >
                               <Row>
                                <Col md={8}>
                                <Row className="mb-4">
                                 <Label
                                   htmlFor="horizontal-email-Input"
                                   className="col-sm-3 col-form-label"
                                 >
                                   Google Api Key <InfoOutlined id="tool_tip_val"/>
                                 </Label>
                                 <UncontrolledTooltip target={'tool_tip_val'}>
                                  <p>This API key is used to provide the accurate location data, help you to intergrate the GOOGLE MAPS with your account</p>
                                 </UncontrolledTooltip>
                                 <Col sm={9}>
                                  {
                                    editKeyMode == false? 
                                    <div className="mt-2">
                                      <p>{GoogleAPIKey} <EditSharp onClick={()=>setEditKeyMode(true)}/> </p>
                                    </div>: 
                                   <Input
                                     type="text"
                                     value={GoogleAPIKey}
                                     onChange={(e)=>setGoogleAPIKey(e.target.value)}
                                     name="google_api"
                                     className="form-control"
                                     placeholder="API KEY ......"
                                   />
                                  }
                                 </Col>
                               </Row>
                                </Col>
                               {
                                editKeyMode == true ? 
                                <Col md={4}>
                                <button className="btn btn-primary">Save</button>  
                                <span style={{marginLeft: 10}} onClick={()=>{
                                  getData();
                                  setEditKeyMode(false)
                                }} className="btn btn-outline-danger">Cancel</span>  
                               </Col>: null
                               }
                               </Row>

                             </Form>
                    </CardBody>
                  </Card>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
      <Offcanvas
          style={{
            // height: 300,
          }}
          isOpen={addDepartmentModal}
          direction="start"
          toggle={() => setaddDepartmentModal(!addDepartmentModal)}
        >
          <OffcanvasHeader toggle={() => setaddDepartmentModal(!addDepartmentModal)}>
            {/* <h4>Add Member</h4> */}
              {
                editModeDepartment == true ? " Edot Department" : " Add Department"
              }
         
          </OffcanvasHeader>
          <OffcanvasBody>
            <Container>
              {editModeDepartment == false? 
             <Form
             onSubmit={(e)=>{
               e.preventDefault();
               let local = localStorage.getItem('authUser');
               let mapsing = JSON.parse(local);
               let org = mapsing.map((e)=>e.org_id);
                // this.setState({loading: true, disable: true});
                if (e.target.elements.dept_name.value  === '') {
                  
                  toastr.error('Department name is required');
                  return false;
                } else if (e.target.elements.location_for.value == '') {
                  
                  toastr.error('Select the Location for department');
                  return false;
                  
                } else {
                  setIsLoading(true);
                  var formdata = new FormData();
                  formdata.append('method', 'create-department-by-admin');
                  formdata.append('name', e.target.elements.dept_name.value);
                  formdata.append('location_for', e.target.elements.location_for.value);
                  formdata.append('org_id', org);
                  var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow',
                  };
                  
                  fetch(process.env.REACT_APP_LEAVE_API_URL+'/departments.php', requestOptions)
                    .then(response => response.text())
                    .then(result => {
                      setIsLoading(false)
                      if (result === 'success') {
                       Swal.fire({
                        icon: 'success',
                        title:'Success',
                        text:'You successfully added the department'
                       })
                       fetchDepartment();
                       setaddDepartmentModal(false)
                      } else {
                        Swal.fire({
                          icon: 'error',
                          title:'Server Error',
                          text:'Check your input fields are try again'
                         })
                      }
                    })
                    .catch(error => console.log('error', error));
                }
              
             }}
             
             >
                <Row>
                  <Col md={12}>
                    <Label>Department Name</Label>
                    <input
                    className="form-control"
                    type="text"
                    name="dept_name"
                    
                    />
                  </Col>
                  <Col md={12}>
                    <Label>Select Location</Label>
                    <Select
                      options={mockDataOther}
                      // onChange={(e)=>(e)}
                      className="select2-selection"
                      name="location_for"
                    />
                  </Col>
                  <Col md={12} style={{ marginTop: 10 }}>
                   {
                    isLoading == false ? 
                    <button type="submit" className="btn btn-primary">
                      Save Department
                    </button>:<Spinner color="info"/>
                   }
                    &nbsp;&nbsp;
                    <button
                      onClick={() => setaddDepartmentModal(!addDepartmentModal)}
                      className="btn btn-danger"
                    >
                      Cancel
                    </button>
                  </Col>
                </Row>
             </Form>
             :
             <Form
             onSubmit={(e)=>{
               e.preventDefault();
               let local = localStorage.getItem('authUser');
               let mapsing = JSON.parse(local);
               let org = mapsing.map((e)=>e.org_id);
                // this.setState({loading: true, disable: true});

                Swal.fire({
                  title:'Confirmation',
                  text:'Are you sure to want to update this department. This will also change your employees department name that are linked to this department',
                  showCancelButton: true ,
                  cancelButtonColor:'red',
                  icon:'warning',
                  cancelButtonText:"No , I don't",
                  confirmButtonColor:'green',
                  confirmButtonText:'Yes, I want'

                }).then((is)=>{
                  if(is.isConfirmed) {

                    setIsLoading(true);
                    var formdata = new FormData();
                    formdata.append('method', 'update-deparment-by-admin');
                    formdata.append('name', singleDept.name);
                    formdata.append('activity_id', singleDept.id);
                    formdata.append('location_for', singleDept.location.value);
                    formdata.append('org_id', org);
                    var requestOptions = {
                      method: 'POST',
                      body: formdata,
                      redirect: 'follow',
                    };
                    
                    fetch(process.env.REACT_APP_LEAVE_API_URL +'/departments.php', requestOptions)
                      .then(response => response.text())
                      .then(result => {
                        setIsLoading(false)
                        if (result === 'success') {
                         toastr.success('You successfully added the department')
                         fetchDepartment();
                         setaddDepartmentModal(false)
                         seteditModeDepartment(false)
                        } else {
                        toastr.error('Check your input fields are try again');
                        }
                      })
                      .catch(error => console.log('error', error));
                  }else{
                    Swal.close()
                  }
                })
              
              
             }}
             
             >
                <Row>
                  <Col md={12}>
                    <Label>Department Name</Label>
                    <input
                    className="form-control"
                    type="text"
                    name="dept_name"
                    value={singleDept.name}
                    onChange= {(e)=>setSingleDept((prev)=>({
                      ...prev,
                      name: e.target.value
                    }))}
                    
                    />
                  </Col>
                  <Col md={12}>
                    <Label>Select Location</Label>
                    <Select
                      options={mockDataOther}
                      value={singleDept.location}
                      onChange={(e)=>setSingleDept((prev)=>({
                        ...prev,
                        location: e
                      }))}
                      className="select2-selection"
                      name="location_for"
                    />
                  </Col>
                  <Col md={12} style={{ marginTop: 10 }}>
                   {
                    isLoading == false ? 
                    <button type="submit" className="btn btn-primary">
                      Edit Department
                    </button>:<Spinner color="info"/>
                   }
                    &nbsp;&nbsp;
                    <button
                      onClick={() => setaddDepartmentModal(!addDepartmentModal)}
                      className="btn btn-danger"
                    >
                      Cancel
                    </button>
                  </Col>
                </Row>
             </Form>
             }
              </Container>
              </OffcanvasBody>
              </Offcanvas>
    </React.Fragment>
  )
}

export default OrganizationSetting
