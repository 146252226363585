import React from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../assets/images/users/avatar-1.jpg"
import profileImg from "../../assets/images/profile-img.png"

const WelcomeComp = ({
  orgName,
  currentUserName,
  profileImage,
  currentRole,
  dataForTask,
}) => {
  const getSchdule = () => {
    let jsonView = JSON.parse(localStorage.getItem("authUser"))
    let currentid = jsonView.map(e => e.user_id)
    let form = new FormData()
  }
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Welcome Back {orgName}!</h5>
                <p>Track Presence Dashboard</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid ">
                <img
                  src={profileImage}
                  style={{ width: 65, height: 65 }}
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div>
              <h5 className="font-size-15 text-truncate ">
              {JSON.parse(localStorage.getItem("authUser")).map(
                  e => e.first_name 
                )}
              </h5>
              
              <p>
                <strong>Start </strong>{" "}
                {JSON.parse(localStorage.getItem("authUser")).map(
                  e =>  e.schedule_info == undefined  ?  null : e.schedule_info.start_time == undefined ?  null: e.schedule_info.start_time 
                )}
              </p>
              <p>
                <strong>End</strong>{" "}
                {JSON.parse(localStorage.getItem("authUser")).map(
                  e => e.schedule_info == undefined ? null :e.schedule_info.end_time == undefined ?  null: e.schedule_info.end_time 
                )}
              </p>
            </Col>

            <Col sm="8">
              <div className="pt-4">
                <Row>
                  <Col xs="12">
                    <p>Task Information</p>
                  </Col>
                  <Col xs="3">
                    <Link to={"../tasks/total"}>
                      <h5 className="font-size-15 text-center">
                        {dataForTask.total}
                      </h5>
                      <p className="text-muted mb-0 text-center">Total</p>
                    </Link>
                  </Col>
                  <Col xs="3">
                    <Link to={"../tasks/active"}>
                      <h5 className="font-size-15 text-center">
                        {dataForTask.active}
                      </h5>
                      <p className="text-muted mb-0 text-center">Active</p>
                    </Link>
                  </Col>
                  <Col xs="3">
                    <Link to={"../tasks/completed"}>
                      <h5 className="font-size-15 text-center">
                        {dataForTask.completed}
                      </h5>
                      <p className="text-muted mb-0 text-center">Overdue</p>
                    </Link>
                  </Col>
                  <Col xs="3">
                    <Link to={"../tasks/completed"}>
                      <h5 className="font-size-15 text-center">
                        {dataForTask.overdue}
                      </h5>
                      <p className="text-muted mb-0 text-center">Completed</p>
                    </Link>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
