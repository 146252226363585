import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  Spinner,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap"
import classnames from "classnames"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"
import Switch from "react-switch"
import {
  Pdate,
  Type,
  Value,
  ValueinUSD,
  Status,
  Coin,
  Tracked,
} from "../CompanyPeoples/MembersData"

import TableContainer from "../../components/Common/TableContainer"

import img2 from "../../assets/images/no-time-off.59f54e64.svg"

//Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { getCryptoOrders } from "store/crypto/actions"
import Select from "react-select"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import axios from "axios"
import { DataGrid, GridRowsProp, GridToolbar,GridColDef } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { Box } from "@mui/material"
import { Link, useLocation, useParams } from "react-router-dom"
const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        style={{ flexShrink: 0 }}
        width="240"
        height="200"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Employee Found</Box>
    </StyledGridOverlay>
  );
}
const AttendanceList = props => {
  //meta title
  
  document.title = `Attendance List | ${process.env.REACT_APP_NAME}`
  const { state } = useLocation()
  const { type } = useParams()
  console.log(state)
  const [tableLoader, setTableLoader] = useState(true)
  const [orders, onGetOrders] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  const [avatarSrc, setAvatarSrc] = useState("")
  const [ByManual, setByManual] = useState(false)
  const [trackStatus, settrackStatus] = useState(false)
  const [filteredData, setFilteredData] = useState([])
  const [currentRole, setCurrentRole] = useState(null)

  useEffect(() => {
    if (type == "total") {
      fetchEmployee()
    } else {
      fetchAttendanceByName(type)
    }
  }, [])
  const fetchEmployee = async () => {
    
    let localVal = JSON.parse(localStorage.getItem("authUser"))
    let emp = localVal.map(e => e.user_id)
    let role = localVal.map(e => e.role)
    setCurrentRole(role)
    let org = localVal.map(e => e.org_id)
    var formdata = new FormData()
    formdata.append("method", "fetch_employees-desktop-by-location")
    formdata.append("emp_id", emp)
    formdata.append("org_id", org)
    formdata.append(
      "location_for",
      state.location == "All Location" ? "All" : state.location
    )
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }
    await fetch(process.env.REACT_APP_LEAVE_API_URL+"/login.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setFilteredData(result)
        if(result.length == 0){
          setTableLoader(false)
        } 
      })
      .catch(error => console.log("error", error))
  }
  const handleFilter = () => {
    var allProduct = orders.filter(order => {
      return (
        order.type === selectType &&
        order.coin === selectCoin &&
        order.status === selectStatus
      )
    })
    setSetProductData(allProduct)
  }
  const fetchAttendanceByName = type => {
    let localValues = localStorage.getItem("authUser")
    let JsonFormat = JSON.parse(localValues)
    let org = JsonFormat.map(e => e.org_id)
    let emp = JsonFormat.map(e => e.user_id)
    let role = JsonFormat.map(e => e.role)
    setCurrentRole(role)
    if (type == "leave") {
      let form = new FormData()
      form.append("method", "filter-fetch-leaves")
      form.append("emp_id", emp)
      form.append("org_id", org)
      form.append("location_for", state.location ? state.location : null)
      form.append("status", state.status ? state.status : null)
      form.append("filter_date", state.filterDate ? state.filterDate : null)

      var requestOptions = {
        method: "POST",
        body: form,
      }
      console.warn(form)
      fetch(process.env.REACT_APP_LEAVE_API_URL+"/leave.php", requestOptions)
        .then(res => res.json())
        .then(result => {
          console.error(result)
          // setLeaveData(result);
         
          setFilteredData(result)
          setTimeout(() => {
            if (result.length == 0) {
              setTableLoader(false);
            }
          }, 1000);
        })
        .catch(e => {
          console.log(e)
        })
    } else {
      let page = 1
      var formdata = new FormData()
      formdata.append("method", "fetch-attendance-for-single-page")
      formdata.append("attendance_type", type)
      formdata.append("filter_date", state.filter_date)
      formdata.append("page_no", page)
      formdata.append("emp_id", emp)
      formdata.append("org_id", org)
      formdata.append(
        "location_for",
        state.location == "All Location" ? "All" : state.location
      )
      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      }
      console.log(formdata)
      fetch(process.env.REACT_APP_LEAVE_API_URL+"/attendance.php", requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.data.length == 0) {
            setTableLoader(false);
          }
          if (result.data.length !== 0) {
            const additionalProperties = result.data.map(e => {
              const {
                emp_id,
                job_title,
                location_punch_in,
                location_punch_out,
                time_punch_in,
                time_punch_out,
              } = e
              const {
                id,
                organization_id,
                first_name,
                last_name,
                username,
                email_address,
                password,
                pin,
                company_id,
                role,
                status,
                phone_number,
                profile_image,
                created_at,
                updated_at,
                shift_start_time,
                shift_end_time,
                extra_hour_status,
                no_of_hours,
                employee_right,
                leave_right,
                mark_right,
                byfaceright,
                byManualright,
                tracking_status,
                owner_status,
                default_login,
                current_mode,
                email_verified,
                fcm_token,
              } = e.data
              let array = {
                emp_id: emp_id,
                job_title: job_title,
                location_punch_in: location_punch_in,
                location_punch_out: location_punch_out,
                time_punch_in: time_punch_in,
                time_punch_out: time_punch_out,
                id: id,
                organization_id: organization_id,
                first_name: first_name,
                last_name: last_name,
                username: username,
                email_address: email_address,
                password: password,
                pin: pin,
                company_id: company_id,
                role: role,
                status: status,
                phone_number: phone_number,
                profile_image: profile_image,
                created_at: created_at,
                updated_at: updated_at,
                shift_start_time: shift_start_time,
                shift_end_time: shift_end_time,
                extra_hour_status: extra_hour_status,
                no_of_hours: no_of_hours,
                employee_right: employee_right,
                leave_right: leave_right,
                mark_right: mark_right,
                byfaceright: byfaceright,
                byManualright: byManualright,
                tracking_status: tracking_status,
                owner_status: owner_status,
                default_login: default_login,
                current_mode: current_mode,
                email_verified: email_verified,
                fcm_token: fcm_token,
              }

              return array
            })
            setFilteredData(additionalProperties)

          }
        })
        .catch(error => console.log("error", error))
    }
  }
  
  const convertTime = inputTime => {
    if (inputTime !== 0) {
      const [hours, minutes, seconds] = inputTime.split(":")
      const dateObj = new Date()
      dateObj.setHours(hours)
      dateObj.setMinutes(minutes)
      dateObj.setSeconds(seconds)
      const formattedTime = dateObj.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })

      return formattedTime.toLowerCase() // Convert to lowercase for consistency with 'pm'
    } else {
      return "N/a"
    }
  }
  const columns : GridColDef[] = [
    { field: 'profile_image', headerName: 'Image', width: 120, renderCell:((param)=>(<img src={param.value} className="avatar-xs rounded-circle"/>))},
    { field: 'id', headerName: 'Full Name', width: 200,renderCell:((param)=>{
      return (
        <div>
          <p>
            <Link
              to={`../../people/members/view/${param.row.emp_id}`}
            >
              {param.row.emp_id}-
              {param.row.first_name + ' ' + param.row.last_name}  
            </Link>
          </p>
        </div>
      )
    }) },
    
    { field: 'job_title', headerName: 'Designation', width: 200 },
    { field: 'time_punch_in', headerName: 'Check IN', width: 250 , renderCell : ((param)=>{
      let array = [];
      if(param.value !== 'n/a' && param.value !=  ""){
         array = param.value.split(' '); 
      }
      return <p>{convertTime(array.length == 0 ? 0: array[1])}</p>
    }) },
    { field: 'time_punch_out', headerName: 'Check Out', width: 250 , renderCell:((param)=>{
      let array = [];
      if(param.value !== 'n/a' && param.value != null){
         array = param.value.split(' '); 
        // alert(param.value)
      }
      return <p>{convertTime(array.length == 0 ? 0: array[1])}</p>
    }) },
    
    // { field: '', headerName: 'Actions', width: 150,renderCell:((param)=>{
      
      
      
      
    //   return (
    //     <ul className="list-unstyled hstack gap-1 mb-0">
    //       <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
         
    //       <Link to={`../../people/members/view/${param.row.id}`} 
    //            className="btn btn-sm btn-soft-primary">
                
    //           <i className="mdi mdi-eye-outline" id="viewtooltip"></i>
    //         </Link>
    //       </li>
    //       <UncontrolledTooltip placement="top" target="viewtooltip">
    //         View
    //       </UncontrolledTooltip>
    //       <li>
    //         <Link
    //           to="#"
    //           className="btn btn-sm btn-soft-danger"
              
    //             onClick={() => {
    //               tog_center();
    //               fetchEmployeeSingleById(param.row.id)
                
    //           }}
    //         >
    //           <i className="mdi mdi-delete-outline" id="deletetooltip" />
    //           <UncontrolledTooltip placement="top" target="deletetooltip">
    //             Terminate
    //           </UncontrolledTooltip>
    //         </Link>
    //       </li>
    //     </ul>
      
    //   )
    // }) } 
  ];
  const OnPressNext = (id, val) => {
    let form = new FormData()
    form.append("method", "update_leave_status")
    form.append("leave_id", id)
    form.append("leave_status", val)
    var requestOptions = {
      method: "POST",
      body: form,
    }
    fetch(process.env.REACT_APP_LEAVE_API_URL+"/leave.php", requestOptions)
      .then(res => res.text())
      .then(result => {
        if (result == "success") {
          let localVar = localStorage.getItem("authUser")
          let localArray = JSON.parse(localVar)
          let emp = localArray.map(e => e.user_id)
          let org = localArray.map(e => e.org_id)
          fetchLeaves(emp, org)
        } else {
          alert("Error while updating the status. Try again")
        }
      })
  }
 
  const Leavecolumns: GridColDef[] = [
    { field: 'profile_image', headerName: 'Image', width: 120, renderCell:((param)=>(<img src={param.value} className="avatar-xs rounded-circle"/>))},
    { field: 'fullname', headerName: 'Full Name', width: 150,renderCell:((param)=>(<p>{param.value}</p>)) },
    { field: 'leave_type', headerName: 'Leave Type', width: 150,renderCell:((param)=>(<p>{param.value}</p>)) },
    { field: 'status', headerName: 'Status', width: 150,renderCell:((param)=>(<p>{param.value}</p>)) },
    { field: '', headerName: 'Actions', width: 150,renderCell:((param)=>{
      if (
        param.row.status == "requested" &&
        currentRole !== "user"
      ) {
        return (
          <div>
            <button
              className="btn btn-success"
              onClick={() => OnPressNext(param.row.id, "approved")}
            >
              {"Approve"}
            </button>
            <button
              className="btn btn-danger"
              onClick={() => OnPressNext(param.row.id, "rejected")}
            >
              {"Reject"}
            </button>
          </div>
        )
      } else {
        return (
          <div>
            <button disabled className="btn btn-success">
              {"Approve"}
            </button>
            <button disabled className="btn btn-danger">
              {"Reject"}
            </button>
          </div>
        )
      }
    }) },
  ]
  function formatDateHiring(inputDate) {
    // Split the input date string into month, day, and year parts
    var parts = inputDate.split("/")
    var month = parseInt(parts[0], 10)
    var day = parseInt(parts[1], 10)
    var year = parseInt(parts[2], 10)

    // Create a Date object using the parsed values
    var date = new Date(year, month - 1, day) // Subtracting 1 from month since it's zero-indexed

    // Get the day and month names
    var dayName = date.getDate()
    var monthName = date.toLocaleString("default", { month: "short" })

    // Format the date as 'day MonthName year'
    var formattedDate = dayName + " " + monthName + " " + year

    return formattedDate
  }
  


  const columnsForTotals: GridColDef[] = [
    { field: 'profile_image', headerName: 'Image', width: 120, renderCell:((param)=>(<img src={param.value} className="avatar-xs rounded-circle"/>))},
    { field: 'id', headerName: 'Emp ID', width: 150,renderCell:((param)=>(<p>{param.value}</p>)) },
    { field: 'first_name', headerName: 'Full Name', width: 150 },
    { field: 'job_title', headerName: 'Designation', width: 150 },
    { field: 'job_department', headerName: 'Department', width: 150 },
    { field: 'super_name', headerName: 'Supervisor Name', width: 150 },
    { field: 'hiring_date', headerName: 'Hiring Date', width: 100,renderCell:((param)=>(<p>{formatDateHiring(param.value)}</p>)) },
    { field: '', headerName: 'Actions', width: 150,renderCell:((param)=>{
      
      
      
      
      return (
        <ul className="list-unstyled hstack gap-1 mb-0">
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
         
          <Link to={`../../people/members/view/${param.row.id}`} 
               className="btn btn-sm btn-soft-success">
                
              <i className="mdi mdi-eye-outline" id="viewtooltip"></i>
            </Link>
          </li>
          <UncontrolledTooltip placement="top" target="viewtooltip">
            View
          </UncontrolledTooltip>
        
        </ul>
      
      )
    }) }
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}

          <Row className="pt-3">
            <Col lg="12">
              <Card>
                <CardBody>
                  <TabContent activeTab={activeTab} className="p-3">
                    <TabPane tabId="1">
                      {/* <p>{JSON.stringify(filteredData)}</p> */}

                      {type !== "total" ? (
                       <Box sx={{ width: '100%',height:'100%' }}>
                          
                       <DataGrid 
                       autoHeight
                       sx={{ '--DataGrid-overlayHeight': '300px' }}
                       slots={{ toolbar:  GridToolbar ,
                        noRowsOverlay: CustomNoRowsOverlay 
                        
                      }}
                      rows={filteredData}
                      loading= {filteredData.length == 0 && tableLoader == true ? true : false}
                      columns={type == "leave" ? Leavecolumns : columns} />
                    
                      </Box>
                   
                      ) : (
                        <Box sx={{ width: '100%',height:'100%' }}>
                          
                        <DataGrid 
                        autoHeight
                        sx={{ '--DataGrid-overlayHeight': '300px' }}
                        slots={{ toolbar:  GridToolbar ,
                         noRowsOverlay: CustomNoRowsOverlay 
                         
                       }}
                       rows={filteredData}
                       loading= {filteredData.length == 0 && tableLoader == true ? true : false}
                       columns={columnsForTotals} />
                     
                       </Box>
                        // <TableContainer
                        //   columns={columnsForTotal}
                        //   data={filteredData}
                        //   isGlobalFilter={true}
                        //   isAddOptions={false}
                        //   customPageSize={10}
                        // />
                      )}
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AttendanceList.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

const mapStateToProps = ({ crypto }) => ({
  orders: crypto.orders,
})

const mapDispatchToProps = dispatch => ({
  onGetOrders: () => dispatch(getCryptoOrders()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AttendanceList))
