import React, { Component, useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { Badge, Card, CardFooter, Col, Container, Row ,Label, Form, Input} from "reactstrap"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import Select from "react-select"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import SalarySlip from "components/Common/SalarySlip"
import Swal from "sweetalert2"
import { Backdrop, CircularProgress } from "@mui/material"
const SkeletonCard = ({ length }) => {
  for (let i = 0; i <= length; i++) {
    return (
      <Col md={12}>
        <Card style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 10 }}>
          <Row>
            <Col md={12}>
              <Row>
                <Col md={1}>
                  <Skeleton circle height={50} width={50} />
                </Col>

                <Col md={4}>
                  <Skeleton height={20} width={100} />
                  <Skeleton height={20} width={80} />
                </Col>
                <Col md={7}>
                  <p style={{ textAlign: "end",paddingRight:10 }}>
                    <Skeleton height={20} width={80} />
                  </p>
                  <p style={{ textAlign: "end" ,paddingRight:10}}>
                    <Skeleton height={20} width={100} />
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
    )
  }
}
const PayrollSlip = () => {
    const {id} = useParams();
 console.log(id)

 
  const [shownSkeleton, setShownSkeleton] = useState(false)
  const [editView, seteditView] = useState(false)
  const [orders, onGetOrders] = useState([])
  const [basicSalary, setBasicSalary ] = useState('');
  const [commision, setCommission ] = useState('');
  const [bonus, setBonus ] = useState('');
  const [Incentive, setIncentive ] = useState('');
  const [OverTime, setOverTime ] = useState('');
  const [MonthFine, setMonthFine ] = useState('');
  const [MonthTax, setMonthTax ] = useState('');
  const [LoanMonth, setLoanMonth ] = useState('');
  const [AdvanceMonth, setAdvanceMonth ] = useState('');
  const [jobMonth, setJobMonth ] = useState('');
  const [jobYear, setJobYear ] = useState('');
  const [cycledate, setCycleDate ] = useState('');
  const [scheduleType, setScheduleType ] = useState('');
  const [open, setOpen ] = useState(false);
  
  // const { myParamObject } = this.props.location.state || {};
  
 
 useEffect(()=>{
  const month = new Date().getMonth() + 1;
  const formattedMonth = month < 10 ? '0' + month : month.toString();
    fetchEmployee(formattedMonth, new Date().getFullYear());
 },[])

  const fetchEmployee = async (month,year) => {
    setOpen(true)
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let emp = localArray.map(e => e.user_id)
    let org = localArray.map(e => e.org_id)
    var formdata = new FormData()
    formdata.append("method", "single_by_id_fetch_payroll_list")
    formdata.append("org_id", org)
    formdata.append("emp_id", id)
    formdata.append("month", month)
    formdata.append("year", year)

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }
    await fetch(process.env.REACT_APP_LEAVE_API_URL+"/salary.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        setOpen(false)
        onGetOrders(result)
        result.map((e)=>{
            let val = e.joing_date.split('/');
            setJobMonth(val[0])
            setJobYear(val[2])
            setScheduleType(e.schedule_off_status)
            setCycleDate(e.cycle_date)
            setBasicSalary(e.basic_salary);
            setCommission(e.month_commission || '0');
            setBonus(e.month_bonus || '0');
            setIncentive(e.month_incentive || '0');
            setOverTime(e.month_overtime || '0');
            setMonthFine(e.trady || '0');
            setMonthTax(e.month_tax || '0');
            setLoanMonth(e.loan_amount || '0');
            setAdvanceMonth(e.advance_salary || '0');

        })
      })
      .catch(error => console.log("error", error))
  }
  const SkeletonTrackedTimeCard = () => (
    <Col md={12}>
      <Card style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 10 }}>
        <Row>
          <Col md={12}>
            <Skeleton height={20} width={150} style={{ marginBottom: 10 }} />
            <Skeleton height={20} width={100} />
          </Col>
          <Row>
            <Col md={6}>
              <Skeleton height={20} width={100} />
              <Skeleton height={20} width={80} />
            </Col>
            <Col md={6}>
              <Skeleton height={20} width={100} />
              <Skeleton height={20} width={80} />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Skeleton height={20} width={100} />
              <Skeleton height={20} width={80} />
            </Col>
            <Col md={6}>
              <Skeleton height={20} width={100} />
              <Skeleton height={20} width={80} />
            </Col>
          </Row>
        </Row>
      </Card>
    </Col>
  );
  function formatJoingDate(inputDate) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    
    const parts = inputDate.split('/');
    const day = parseInt(parts[1], 10);
    const month = months[parseInt(parts[0], 10) - 1];
    const year = parseInt(parts[2], 10);

    return day + ' ' + month + ' ' + year;
}
function getMonthName(monthValue) {
    // Create a Date object with the given month value (subtract 1 since months are zero-indexed)
    var date = new Date();
    date.setMonth(monthValue - 1);

    // Get the month name in the default locale
    var monthName = date.toLocaleString('default', { month: 'long' });

    // Extract the first three characters of the month name
    var shortMonthName = monthName.slice(0, 3);

    return shortMonthName;
}
function getCurrentMonthValue() {
  // Get the current month value (zero-based) from the Date object
  const month = new Date().getMonth() + 1; // Adding 1 to convert it to 1-based month

  // Format the month value to ensure it has leading zero if needed
  const formattedMonth = month < 10 ? '0' + month : month.toString();

  return formattedMonth;
}
const [selectedMulti2, setselectedMulti2] = useState({label: String(getMonthName((new Date().getMonth()+1))), value :getCurrentMonthValue()})
  const [selectedMulti23, setselectedMulti23] = useState({label: new Date().getFullYear(), value :new Date().getFullYear()})
function handleMulti2(selectedMulti2) {
    setselectedMulti2(selectedMulti2)
  }
  function handleMulti23(selectedMulti23) {
    setselectedMulti23(selectedMulti23)
  }
function getNext20YearsArray() {
    const currentYear = new Date().getFullYear();
    const yearsArray = [];

    for (let i = 0; i < 21; i++) { // Change loop condition to < 21 to include current year and next 20 years
        const year = currentYear + i;
        yearsArray.push({
            label: year.toString(),
            value: year.toString()
        });
    }

    return yearsArray;
}
const next20Years = getNext20YearsArray();
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
          <Row>
            <Col md={12}>
                {
                    shownSkeleton == true ? 
                    <SkeletonTrackedTimeCard/>
                    :
                   <div>
                     <Card
                        style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 10 }}
                    >
                        <Row>
                        <Col md={6}>
                            <p style={{ fontSize: 18, fontWeight: "600",paddingBottom:20 }}>
                              Pay Slip
                            </p>
                            {/* <p>{convertDateFormat(state.obj.created_at)}</p> */}
                        </Col>
                       
                        <Row>
                            <Col md={2}>
                                <img src={orders.map((e)=>e.profile_image || "https://via.placeholder.com/200")} className="rounded-circle avatar-lg"/>
                            </Col>
                            {
                               orders.map((e,index)=>{
                                return(
                                        <Col key={index} md={3}>

                                        <p style={{ fontWeight: "600" }}>Employee Name <span style={{ fontWeight: "normal" }}>{e.first_name + ' ' + e.last_name}</span></p>
                                        <p style={{ fontWeight: "600" }}>Designation <span style={{ fontWeight: "normal" }}>{e.job_title ? e.job_title : 'N/a' }</span></p>
                                        <p style={{ fontWeight: "600" }}>Hiring Date <span style={{ fontWeight: "normal" }}>{formatJoingDate(e.joing_date)}</span></p>
                                        <p style={{ fontWeight: "600" }}>Payroll Cycle <span style={{ fontWeight: "normal" }}>{cycledate  + ' to ' + cycledate }</span></p>
                                        <p style={{ fontWeight: "600" }}>Schedule Off <span style={{ fontWeight: "normal" }}>{ e.weekdays + ' Days'}</span></p>
                                        <p style={{ fontWeight: "600" }}>Schedule Off Paid  <span style={{ fontWeight: "normal" }}>{ e.schedule_off_status }</span></p>

                                            
                                        </Col>
                                );
                               })
                            }
                            <Col md={6}>
                            <Form 
                        onSubmit={(e)=>{
                            e.preventDefault();
                            let month = e.target.elements.month_for.value;
                            let year = e.target.elements.year_for.value;

                            var currentYear = new Date().getFullYear();
                            var currentMonth = new Date().getMonth() + 1; // Adding 1 since months are zero-indexed
                           console.log(month)
                           console.log(year)
                           console.log(jobMonth)
                           console.log(jobYear)
                            // Check if the selected month and year are not empty
                            if (month === '' || year === '') {
                                toastr.error('Select both month and year to see the slip');
                                return false;
                            }
                            
                            // Convert month and year to numbers for comparison
                            var selectedMonth = parseInt(month, 10);
                            var selectedYear = parseInt(year, 10);
                        
                            // Check if the selected year is not greater than the current year
                            if (selectedYear > currentYear) {
                                toastr.error('Select a year not greater than the current year');
                                return false;
                            }
                        
                            // // Check if the selected month is not greater than the current month if the year is the current year
                            if (selectedYear === currentYear && selectedMonth > currentMonth) {
                                toastr.error('Select a month not greater than the current month');
                                return false;
                            }
                              
                            if(month > parseFloat(jobMonth) &&  year > parseFloat(jobYear)){
                              toastr.error('Selected month less than hiring month');
                              return false;
                            }
                            else{
                                fetchEmployee(month , year)
                            }
                            // if(e.target.elements.month_for.value == ''){
                            //     toastr.error('Select month  for which you want see slip');
                            //     return false
                            // }else if(
                            //     e.target.elements.year_for.value == ''
                            // ){
                            //     toastr.error('Select year  for which you want see slip');
                            //     return false
                            // }
                            
                        }}
                        >
                                <Row>

                            <Col className="col-4">
                            <div className="mb-3 ">
                                <Label>Select Month</Label>
                                <Select
                                name="month_for"
                                onChange={e => handleMulti2(e)}
                                options={[
                                    { label: "Jan", value: "01" },
                                    { label: "Feb", value: "02" },
                                    { label: "Mar", value: "03" },
                                    { label: "Apr", value: "04" },
                                    { label: "May", value: "05" },
                                    { label: "Jun", value: "06" },
                                    { label: "Jul", value: "07" },
                                    { label: "Aug", value: "08" },
                                    { label: "Sep", value: "09" },
                                    { label: "Oct", value: "10" },
                                    { label: "Nov", value: "11" },
                                    { label: "Dec", value: "12" }
                                ]}
                                value={selectedMulti2}
                                className="select2-selection"
                            
                                />
                            </div>
                            </Col>
                            <Col className="col-4">
                            <div className="mb-3">

                                <Label>Select Year</Label>
                                <Select
                                name="year_for"
                                onChange={e => handleMulti23(e)}
                                options={next20Years}
                                value={selectedMulti23}
                                className="select2-selection"
                                />
                            </div>
                            </Col>
                            <Col className="col-4">
                            <div className="mb-3" style={{paddingTop:27}}>
                            <button className="btn btn-primary">Get Pay Slip</button>
                            </div>
                            </Col>
                        </Row>
                            </Form>
                            </Col>
                            
                            
                        </Row>
                       
                        </Row>
                    </Card>
                    <Card
                        style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 20,paddingRight: 10 }}
                    >
                        <Row>
                        <Col md={6}>
                            <p style={{ fontSize: 18, fontWeight: "600",paddingBottom:20 }}>
                              Pay Slip information of {selectedMulti2.label} {selectedMulti23.value}
                            </p>
                            {/* <p>{convertDateFormat(state.obj.created_at)}</p> */}
                        </Col>
                        <Col md={6} style={{textAlign:'end'}}>
                           <button className={`btn ${editView == true ? "btn-success" : "btn-primary"}`} onClick={()=>seteditView(!editView)}>{editView == true ? 'View Info' : 'Edit Info'}</button>
                            {/* <p>{convertDateFormat(state.obj.created_at)}</p> */}
                        </Col>
                        <Row>
                            <Col md={12}>
                                
                                 {
                                    orders.length == 0 ? null   : <SalarySlip monthFor={selectedMulti2.label} yearFor={selectedMulti23.value} employee={orders} editView={editView}/>
                                 }
                            </Col>
                        </Row>
                          {
                            editView == true ? 
                            <Form
                            onSubmit={(e)=>{
                                e.preventDefault();
                                console.log(e)
                                Swal.fire({
                                    title: 'Confirmed?',
                                    html:'Are you sure want to save changes?',
                                    showCancelButton: true,
                                    confirmButtonText: 'Yes, Save Changes',
                                    cancelButtonText: 'Discard Changes',
                                    cancelButtonColor:'red',
                                    backdrop:'static',
                                    confirmButtonColor:'green'
                                }).then((is)=>{
                                    if(is.isConfirmed){
                                        let localVal = JSON.parse(localStorage.getItem('authUser')).map((e)=>e.org_id);
                                        let form  = new FormData();
                                        form.append('method', 'salary-information-updated')
                                        form.append('emp_id', id)
                                        form.append('org_id', localVal[0])
                                        form.append('schedule_off', e.target.elements.schedule_off.value);
                                        form.append('month', selectedMulti2.value)
                                        form.append('year', selectedMulti23.value)
                                        form.append('commission', e.target.elements.commission_salary.value)
                                        form.append('bonus',  e.target.elements.bonus_salary.value)
                                        form.append('incentive',  e.target.elements.incentive_salary.value)
                                        form.append('overtime',  e.target.elements.overtime_salary.value)
                                        form.append('month_fine',  e.target.elements.fine_deducation.value)
                                        form.append('month_tax',  e.target.elements.tax_deducation.value)
                                        form.append('month_loan',  e.target.elements.loan_amount.value)
                                        form.append('month_adv',  e.target.elements.advance_amount.value)
                                        const requestOptions = {
                                            method: "POST",
                                            
                                            body: form,
                                            redirect: "follow"
                                          };
                                          
                                          fetch(process.env.REACT_APP_LEAVE_API_URL+"/salary.php", requestOptions)
                                            .then((response) => response.text())
                                            .then((result) => {
                                                if(result == 'success'){
                                                    Swal.fire('Success' , `You successfully update the payroll information of the ${selectedMulti2.label}-${selectedMulti23.value}`).then((is)=>{
                                                        if(is.isConfirmed){
                                                            fetchEmployee(selectedMulti2.value , selectedMulti23.value)
                                                            seteditView(false)

                                                        }
                                                    })
                                                }
                                            })
                                            .catch((error) => console.error(error));

                                    }
                                })
                            }}
                            >
                               
                            <Row>
                            
                            <Col md={12}>
                                <Row>
                             <Col md={6}>
                             <p style={{ fontWeight: "600" }}>Basic Salary</p>
                             <input disabled className="form-control" placeholder="Basic salary" value={basicSalary} onChange={(e)=>setBasicSalary(e.target.value)} name="basic_salary" /> 
                             </Col>
                               
                                </Row>
                                <Row>
                             <Col md={6} className="mt-3">
                             <p style={{ fontWeight: "600" }}>Schedule Off Paid</p>
                               <Input
                               type="radio"
                               name="schedule_off"
                               id="schedule_off1"
                               value={'Yes'}
                               defaultChecked={scheduleType == 'Yes' ? true : false}
                               
                               /> <Label htmlFor="schedule_off1" style={{paddingRight:20}}>Yes</Label>

                               <Input
                               
                               type="radio"
                               defaultChecked={scheduleType == 'No' ? true : false}
                               name="schedule_off"
                               id="schedule_off2"
                               value={'No'}
                               
                               /> <Label htmlFor="schedule_off2">No</Label>
                             </Col>
                               
                                </Row>
                            </Col>
                            <Col md={6}>
                            <Row>
                            <Col md={12}>
                            <p style={{ fontSize: 18, fontWeight: "600",paddingTop:20 }}>
                              Salary Addons
                            </p>
                            </Col>
                             <Col md={12}>
                                <Row>
                                    <Col md = {12}>
                                    <p style={{ fontWeight: "600",paddingTop:10 }}>Sale Commission</p>
                                     <input  className="form-control" placeholder="Sale Commission" value={commision} onChange={(e)=>setCommission(e.target.value)} name="commission_salary" />
                                    </Col>
                                    <Col md = {12}>
                                    <p style={{ fontWeight: "600",paddingTop:10 }}>Bonus</p>
                                     <input className="form-control" value={bonus} onChange={(e)=>setBonus(e.target.value)} placeholder="Bounus" name="bonus_salary" />
                                    </Col>
                                    <Col md = {12}>
                                    <p style={{ fontWeight: "600" ,paddingTop:10}}>Incentive</p>
                                     <input className="form-control" value={Incentive} onChange={(e)=>setIncentive(e.target.value)} placeholder="Incentive" name="incentive_salary" />
                                    </Col>
                                    <Col md = {12}>
                                    <p style={{ fontWeight: "600",paddingTop:10 }}>Overtime</p>
                                     <input className="form-control" value={OverTime} onChange={(e)=>setOverTime(e.target.value)} placeholder="Overtime" name="overtime_salary" />
                                    </Col>
                                </Row>
                          
                            </Col>

                            
                            </Row>
                            </Col>
                            <Col md={6}>
                            <Row>
                            <Col md={12}>
                            <p style={{ fontSize: 18, fontWeight: "600",paddingTop:20 }}>
                              Salary Deducations
                            </p>
                            </Col>
                             <Col md={12}>
                                <Row>
                                   
                                    <Col md = {12}>
                                    <p style={{ fontWeight: "600",paddingTop:10 }}>Fine</p>
                                     <input className="form-control" placeholder="Fine " value={MonthFine} onChange={(e)=>setMonthFine(e.target.value)} name="fine_deducation" />
                                    </Col>
                                    <Col md = {12}>
                                    <p style={{ fontWeight: "600" ,paddingTop:10}}>Tax</p>
                                     <input className="form-control" placeholder="Tax" value={MonthTax} onChange={(e)=>setMonthTax(e.target.value)} name="tax_deducation" />
                                    </Col>
                                    <Col md = {12}>
                                    <p style={{ fontWeight: "600",paddingTop:10 }}>Loan</p>
                                     <input className="form-control" value={LoanMonth} onChange={(e)=>setLoanMonth(e.target.value)} placeholder="Loan Amount" name="loan_amount" />
                                    </Col>
                                    <Col md = {12}>
                                    <p style={{ fontWeight: "600",paddingTop:10 }}>Advance</p>
                                     <input value={AdvanceMonth} onChange={(e)=>setAdvanceMonth(e.target.value)} className="form-control" placeholder="Advance amount" name="advance_amount" />
                                    </Col>
                                </Row>
                          
                            </Col>

                            
                            </Row>
                            </Col>
                            
                        </Row>
                        
                          <div style={{textAlign:'end',paddingTop: 40,paddingBottom: 40,paddingRight: 35}}>
                            <button className="btn btn-primary" type="submit">Save Changes</button>&nbsp;
                            <button className="btn btn-success">Save Changes & Print</button>
                          </div>
                          </Form>: null
                          }
                        
                       
                       
                        </Row>
                    </Card>
                   </div>
                }
            </Col>
            
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default PayrollSlip
