import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

import firebase from 'firebase/app';
import {GoogleAuthProvider , signInWithPopup,getAuth, FacebookAuthProvider} from 'firebase/auth'
import { useSelector, useDispatch, connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// actions
import { loginUser, socialLogin } from "../../store/actions";

// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo.svg";

//Import config
import { facebook, google } from "../../config";
import axios from "axios";
import Swal from "sweetalert2";
import CarouselPage from "pages/AuthenticationInner/CarouselPage";
import { setGoogleApiKey } from "store/google-key/actions";

const Login = props => {
  const local =localStorage.getItem('authUser');
  if(local !== null){
    window.location.href = '/dashboard'
  }
  //meta title
  document.title = `Login | ${process.env.REACT_APP_NAME}`;


  const dispatch = useDispatch();
  const [passwordShow, setPasswordShow] = useState(false);
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "" || '',
      password: "" || '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      // dispatch(loginUser(values, props.router.navigate));
      let form = new FormData();
      form.append('method', 'LoginUser');
      form.append('username', values.email);
      form.append('password', values.password);
      // alert("Done Successfully");
     
      axios.post(process.env.REACT_APP_LEAVE_API_URL+'/login.php', form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          // Handle the response from the API
          console.log('API Response:', );
          if(response.data.message == 'success'){
            if (response.data.message !== 'success') {
              Swal.fire({
                title:'Error',
                text: 'Input fields are invalid. Please try again',
                // showConfirmButton:false,
                icon:'error',
                confirmButtonColor:'#556ee6',
                // showCloseButton:true,
                
  
              }) ;

            } else if (response.data.message == 'success') {
              let org = response.data.user_data.map((e)=>e.org_id);
              let form = new FormData();
              form.append('method','fetch_organization_google_key');
              form.append('org_id',org)
              axios.post(process.env.REACT_APP_LEAVE_API_URL + '/company.php' , form).then((res)=>{
                if(res.data.message == 'found'){
                  
                  dispatch(setGoogleApiKey(res.data.key))
                  localStorage.setItem("authUser" , JSON.stringify(response.data.user_data))
                  window.location.href = '/dashboard'
                }else{
                  // alert('notjing')
                  localStorage.setItem("authUser" , JSON.stringify(response.data.user_data))
                  window.location.href = '/dashboard'
                }
               
              })
            }
          }else{
            Swal.fire({
              title:'Error',
              text: `${response.data.message}. Please try again`,
              // showConfirmButton:false,
              icon:'error',
              confirmButtonColor:'#556ee6',
              // showCloseButton:true,
              

            }) ;
            
          }
        })
    }
  });

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));

  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.router.navigate, type));
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.router.navigate, type));
    }
  };

  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, "google");
  };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, "facebook");
  };
  const handleThisByMe = async () => {
    try {
      const googleProvider = new GoogleAuthProvider();
      const auth = getAuth();
      const result = await signInWithPopup(auth, googleProvider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      
      const user = result.user;
      console.log(user.displayName);
      console.log(user.email);
      if(user.email){
        let form = new FormData();
        form.append('method', 'check_email_Exits');
        form.append('email', user.email);
        var requestOptions = {
          method: 'POST',
          body: form,
          redirect: 'follow'
        };
        await fetch(process.env.REACT_APP_LEAVE_API_URL+"/login.php", requestOptions)
        .then((res)=>res.json())
        .then((result)=>{
          if(result.message == 'nothing_found'){
            window.location.href = `/register?email=${user.email}&fullname=${user.displayName}`
          }else{
            localStorage.setItem('authUser',JSON.stringify(result.user_data));
            window.location.href= '/dashboard';
          }
        })      
      }
    } catch (error) {
      console.error(error);
    }


}

const signInWithFacebook = async () => {
  try {
    const facebookProvider = new FacebookAuthProvider();
    const auth = getAuth();
    const result = await signInWithPopup(auth, facebookProvider);
    const credential = FacebookAuthProvider.credentialFromResult(result);
    const user = result.user;
    console.log(user);
    if(user.email){
      let form = new FormData();
      form.append('method', 'check_email_Exits');
      form.append('email', user.email);
      var requestOptions = {
        method: 'POST',
        body: form,
        redirect: 'follow'
      };
      await fetch(process.env.REACT_APP_LEAVE_API_URL+"/login.php", requestOptions)
      .then((res)=>res.json())
      .then((result)=>{
        
          console.log(result.message);
          if(result.message == 'nothing_found'){
            window.location.href = `/register?email=${user.email}&fullname=${user.displayName}`
          }else{
            localStorage.setItem('authUser',JSON.stringify(result.user_data));
            window.location.href= '/dashboard';
          }
        
      })      
    }
  } catch (error) {
    console.error(error);
  }
};
  return (
    <React.Fragment>
  
        <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />
            <Col  xl={3}>
            <div className="auth-full-page-content p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                <div className=" bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to {process.env.REACT_APP_NAME} .</p>
                      </div>
                    </Col>
                    {/* <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col> */}
                  </Row>
                </div>
                
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                        <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">Username or Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                    
                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <div className="input-group auth-pass-inputgroup">
                              <Input
                                name="password"
                                value={validation.values.password || ""}
                                type={passwordShow ? "text" : "password"}
                                placeholder="Enter Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.password && validation.errors.password ? true : false
                                }
                              />
                              <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light " type="button" id="password-addon">
                                <i className="mdi mdi-eye-outline"></i></button>
                        </div>
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                          

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                              Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                  <div className="mt-5 text-center">
                <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} {process.env.REACT_APP_NAME}. Crafted with{" "}
                  <i className="mdi mdi-account text-info" /> by {process.env.REACT_APP_NAME_COMPANY}
                </p>
              </div>
                  </div>
                  </div>
                </div>
                
              
              {/* <Form action="dashboard"> */}

{/* <div className="mt-4 text-center">
  <h5 className="font-size-14 mb-3">Sign up using</h5>

  <ul className="list-inline">
    <li className="list-inline-item">
      <Link
        onClick={signInWithFacebook}
        className="social-list-item bg-primary text-white border-primary me-1"
      >
        <i className="mdi mdi-facebook"></i>
      </Link>
    </li>
    {/* <li className="list-inline-item">
      <Link
        to="#"
        className="social-list-item bg-info text-white border-info me-1"
      >
        <i className="mdi mdi-twitter"></i>
      </Link>
    </li> 
    <li className="list-inline-item">
      <Link
        onClick={handleThisByMe}
        className="social-list-item bg-danger text-white border-danger"
      >
        <i className="mdi mdi-google"></i>
      </Link>
    </li>
  </ul>
</div> */}
{/* </Form> */}
            
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


Login.propTypes = {
  history: PropTypes.object,
};


export default withRouter(Login);

