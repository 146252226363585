import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Table,
  Form,
  CardTitle,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Spinner,
  UncontrolledTooltip,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Modal,
} from "reactstrap"
import { Skeleton } from "@mui/material"
import classnames from "classnames"
import Select from "react-select"
import img3 from "../../assets/images/apibanner.svg"
import img2 from "../../assets/images/card.df0f73fc.svg"

import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

import TableContainer from "../../components/Common/TableContainer"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
//Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import Breadcrumbs from "components/Common/Breadcrumb"
import { formatAmount } from "App"

const Loan = props => {
  //meta title

  const [activeTab, setActiveTab] = useState("1")
  const [BreaksNo, setNoofBreaks] = useState(0)
  const [orgName, setOrgName] = useState("")
  const [role, setCurrentrole] = useState(JSON.parse(localStorage.getItem('authUser')).map((e)=>e.role))
  const [orgCat, setOrgCat] = useState("")
  const [selectorgCat, setSelectOrgCat] = useState([])
  const [spinner, setSpinner] = useState(false)
  const [showPlaceholder, setShowPlaceHolder] = useState(false)
  const [mockData, setmockData] = useState([])
  const [loanAmount, setLoanAmount] = useState("")
  const [noOfCycles, setNoofCycles] = useState("")
  const [perCycleAmount, setPerCycleAmount] = useState("")
  const [spinnerForLoan, setSpinnerForLoan] = useState(false)
  const [loanData, setLoanData] = useState([])
  const [AdvanceData, setAdvanceData] = useState([])
  const [AllowanceData, setAllowanceData] = useState([])
  const [ModalType, setModalType] = useState('');
  
  const [SingleLoanData, setSingleLoanData] = useState({
    loan_id: '',
    emp_id:{label: '', value: ''},
    loan_amount: '',
    loan_currency: {label: '', value: ''},
    loan_date: '',
    comments:''
  })
  const [SingleAdvanceSalaryData, setSingleAdvanceSalaryData] = useState({
    advance_id: '',
    emp_id:{label: '', value: ''},
    adv_amount: '',
    adv_currency: {label: '', value: ''},
    adv_date: '',
    comments:''
  })
  const [SingleAllowanceData, setSingleAllowanceData] = useState({
    allowance_id: '',
    emp_id:{label: '', value: ''},
    amount: '',
    currency: {label: '', value: ''},
    issue_date: '',
    allowance_include: '',
    allowance_type:{label: '', value: ''},
    in_salary: '',
  })
  const [isRight , setIsRight] = useState(false)
  const [source , setSource] = useState('')
  useEffect(() => {
    fetchEmployeeList()
    fetchLoanFor();
    fetchAllowanceFor();
  }, [])
  document.title = `${
    activeTab == "1" ? "Loan Request" : (activeTab == "2" ?  "Advance Salary" : "Allowance")
  }| ${process.env.REACT_APP_NAME}`;
  const ProcessOnChangeStatus = (id,val)=>{
        let form = new FormData();
        form.append('method','update_loan_status')
        form.append('loan_id',id)
        form.append('loan_status',val)
            const requestOptions = {
            method: "POST",
            body: form,
            redirect: "follow"
            };

            fetch(process.env.REACT_APP_LEAVE_API_URL+"/loan.php", requestOptions)
            .then((response) => response.text())
            .then((result) => {
                if(result == 'success'){
                    fetchLoanFor()
                    Swal.fire('Success','You successfully change the loan status');
                }else{
                    Swal.fire('Server Error','Error comes while updating loan status please try again.');
                }
            })
            .catch((error) => console.error(error));
  }
  const ProcessOnChangeStatusAdv = (id,val)=>{
        let form = new FormData();
        form.append('method','update_adv_status')
        form.append('adv_id',id)
        form.append('adv_status',val)
            const requestOptions = {
            method: "POST",
            body: form,
            redirect: "follow"
            };

            fetch(process.env.REACT_APP_LEAVE_API_URL+"/salary.php", requestOptions)
            .then((response) => response.text())
            .then((result) => {
                if(result == 'success'){
                    fetchAdvanceFor()
                    Swal.fire('Success','You successfully change the salary status');
                }else{
                    Swal.fire('Server Error','Error comes while updating salary status please try again.');
                }
            })
            .catch((error) => console.error(error));
  }
   
    const columns = useMemo(
      () => [
       
        {
          Header: "Member Name",
          accessor: "fullname",
          filterable: true,
          Cell: cellProps => {
            return (
              <div>
                <p>
                  {cellProps.value}
                </p>
              </div>
            )
          },
        },
        {
          Header: "Loan Date",
          accessor: "loan_date",
          filterable: true,
          Cell: cellProps => {
            return (
                <div>
                  <p>
                    {formatDate(cellProps.value)}
                  </p>
                </div>
              )
          },
        },
        {
          Header: "Loan Amount",
          accessor: "loan_amount",
          filterable: true,
          Cell: cellProps => {
            return (
                <div>
                  <p>
                    {formatAmount(parseFloat(cellProps.value),cellProps.cell.row.original.loan_currency)}
                   
                  </p>
                </div>
              )
          },
        },
        {
          Header: "Status",
          accessor: "loan_status",
          filterable: true,
          Cell: cellProps => {
            return (
                <div>
                  <p>
                    {cellProps.value}
                  </p>
                </div>
              )
          },
        },
        {
          Header: "Actions",
          accessor: "id",
          filterable: true,
          Cell: cellProps => {
            let local = JSON.parse(localStorage.getItem('authUser'));
            let role = local.map((e)=>e.role);
                    if(cellProps.cell.row.original.loan_status == 'assigned'){

                        return (
                            <ul className="list-unstyled hstack gap-1 mb-0">
                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                            <Button  
                                 onClick={()=>toggleRightCanvas(cellProps.value, 'loan')}
                                 className="btn btn-sm btn-soft-primary">
                                 
                                <i className="mdi mdi-eye-outline" id="viewtooltip"></i>
                              </Button>
                            </li>
                            <UncontrolledTooltip placement="top" target="viewtooltip">
                              View
                            </UncontrolledTooltip>
                            <li>
                              <Button
                                
                                disabled={role == 'user' ? true : false}
                                className="btn btn-sm btn-soft-success"
                                onClick={()=>ProcessOnChangeStatus(cellProps.value,'approved')}
                              >
                                <i className="mdi mdi-check" id="deletetooltipa" />
                                <UncontrolledTooltip placement="top" target="deletetooltipa">
                                  Approved
                                </UncontrolledTooltip>
                              </Button>
                            </li>
                            <li>
                              <Button
                                disabled={role == 'user' ? true : false}
                                className="btn btn-sm btn-soft-danger"
                                onClick={()=>ProcessOnChangeStatus(cellProps.value,'rejected')}
                              >
                                <i className="mdi mdi-close" id="deletetooltipss" />
                                <UncontrolledTooltip placement="top" target="deletetooltipss">
                                  Rejected
                                </UncontrolledTooltip>
                              </Button>
                            </li>
                          </ul>
                            // <div >
                              
                            //   <button disabled={role == 'user' ? true : false} className="btn btn-success" onClick={()=>ProcessOnChangeStatus(cellProps.value,'approved')}>{"Approved"}</button>
                            //   <button disabled={role == 'user' ? true : false} className="btn btn-danger" onClick={()=>ProcessOnChangeStatus(cellProps.value,'rejected')}>{"Reject"}</button>
                            // </div>
                          )
                        }else{
                        return (
                            <ul className="list-unstyled hstack gap-1 mb-0">
                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                             <Button onClick={()=>toggleRightCanvas(cellProps.value, 'loan')}
                                 className="btn btn-sm btn-soft-primary">
                                  
                                <i className="mdi mdi-eye-outline" id="viewtooltip"></i>
                              </Button>
                            </li>
                            <UncontrolledTooltip placement="top" target="viewtooltip">
                              View
                            </UncontrolledTooltip>
                            <li>
                              <Button
                              disabled
                                
                                className="btn btn-sm btn-soft-success"
                                onClick={() => {
                                  // const jobData = cellProps.row.original;
                                  // onClickDelete(jobData);
                                }}
                              >
                                <i className="mdi mdi-check" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                  Approved
                                </UncontrolledTooltip>
                              </Button>
                            </li>
                            <li>
                              <Button
                               disabled
                                
                                className="btn btn-sm btn-soft-danger"
                                onClick={() => {
                                  // const jobData = cellProps.row.original;
                                  // onClickDelete(jobData);
                                }}
                              >
                                <i className="mdi mdi-close" id="deletetooltips" />
                                <UncontrolledTooltip placement="top" target="deletetooltips">
                                  Rejected
                                </UncontrolledTooltip>
                              </Button>
                            </li>
                          </ul>
                          )

                    }
              
           
          },
        },

      ],
      []
    )
    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    }
    
    const columnsAdvance =  useMemo(
        () => [
        
          {
            Header: "Member Name",
            accessor: "fullname",
            filterable: true,
            Cell: cellProps => {
              return (
                <div>
                  <p>
                    {cellProps.value}
                  </p>
                </div>
              )
            },
          },
          {
            Header: "Issue Date",
            accessor: "issue_date",
            filterable: true,
            Cell: cellProps => {
              return (
                  <div>
                    <p>
                      {formatDate(cellProps.value)}
                    </p>
                  </div>
                )
            },
          },
          {
            Header: "Advance Amount",
            accessor: "amount",
            filterable: true,
            Cell: cellProps => {
              return (
                  <div>
                    <p>
                      {formatAmount(parseFloat(cellProps.value),cellProps.cell.row.original.currency)}
                    
                    </p>
                  </div>
                )
            },
          },
          {
            Header: "Status",
            accessor: "adv_status",
            filterable: true,
            Cell: cellProps => {
              return (
                  <div>
                    <p>
                      {cellProps.value}
                    </p>
                  </div>
                )
            },
          },
          {
            Header: "Actions",
            accessor: "id",
            filterable: true,
            Cell: cellProps => {
              let local = JSON.parse(localStorage.getItem('authUser'));
              let role = local.map((e)=>e.role);
              if(cellProps.cell.row.original.adv_status == 'assigned'){

                return (
                    <ul className="list-unstyled hstack gap-1 mb-0">
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                <Button  
                                    id={`${cellProps.value}`}
                                    onClick={()=>toggleRightCanvas(cellProps.value, 'advance')}
                                    className="btn btn-sm btn-soft-primary">
                                    
                                    <i className="mdi mdi-eye-outline" id="viewtooltips"></i>
                                  </Button>
                                </li>
                                <UncontrolledTooltip placement="top" target="viewtooltips">
                                  View
                                </UncontrolledTooltip>
                                <li>
                                  <Button
                                    
                                    disabled={role == 'user' ? true : false}
                                    className="btn btn-sm btn-soft-success"
                                    onClick={()=>ProcessOnChangeStatusAdv(cellProps.value,'approved')}
                                  >
                                    <i className="mdi mdi-check" id="deletetooltipas" />
                                    <UncontrolledTooltip placement="top" target="deletetooltipas">
                                      Approved
                                    </UncontrolledTooltip>
                                  </Button>
                                </li>
                                <li>
                                  <Button
                                    disabled={role == 'user' ? true : false}
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={()=>ProcessOnChangeStatusAdv(cellProps.value,'rejected')}
                                  >
                                    <i className="mdi mdi-close" id="deletetooltipsss" />
                                    <UncontrolledTooltip placement="top" target="deletetooltipsss">
                                      Rejected
                                    </UncontrolledTooltip>
                                  </Button>
                                </li>
                              </ul>
                  
                  )
                }else{
                return (
                    <ul className="list-unstyled hstack gap-1 mb-0">
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                <Button 
                                  id={`${cellProps.value}`}
                                onClick={()=>toggleRightCanvas(cellProps.value, 'advance')}
                                    className="btn btn-sm btn-soft-primary">
                                      
                                    <i className="mdi mdi-eye-outline" id="viewtooltipx"></i>
                                  </Button>
                                </li>
                                <UncontrolledTooltip placement="top" target="viewtooltipx">
                                  View
                                </UncontrolledTooltip>
                                <li>
                                  <Button
                                  disabled
                                    
                                    className="btn btn-sm btn-soft-success"
                                    onClick={() => {
                                      // const jobData = cellProps.row.original;
                                      // onClickDelete(jobData);
                                    }}
                                  >
                                    <i className="mdi mdi-check" id="deletetooltipq" />
                                    <UncontrolledTooltip placement="top" target="deletetooltipq">
                                      Approved
                                    </UncontrolledTooltip>
                                  </Button>
                                </li>
                                <li>
                                  <Button
                                  disabled
                                    
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => {
                                      // const jobData = cellProps.row.original;
                                      // onClickDelete(jobData);
                                    }}
                                  >
                                    <i className="mdi mdi-close" id="deletetooltipws" />
                                    <UncontrolledTooltip placement="top" target="deletetooltipws">
                                      Rejected
                                    </UncontrolledTooltip>
                                  </Button>
                                </li>
                              </ul>
                  )

            }
            
            },
          },

        ],
        []
      )
    const columnsAllowance =  useMemo(
        () => [
        
          {
            Header: "Member Name",
            accessor: "fullname",
            filterable: true,
            Cell: cellProps => {
              return (
                <div>
                  <p>
                    {cellProps.value}
                  </p>
                </div>
              )
            },
          },
          {
            Header: "Issue Date",
            accessor: "issue_date",
            filterable: true,
            Cell: cellProps => {
              return (
                  <div>
                    <p>
                      {formatDate(cellProps.value)}
                    </p>
                  </div>
                )
            },
          },
          {
            Header: "Amount",
            accessor: "amount",
            filterable: true,
            Cell: cellProps => {
              return (
                  <div>
                    <p>
                      {formatAmount(parseFloat(cellProps.value),cellProps.cell.row.original.currency)}
                    
                    </p>
                  </div>
                )
            },
          },
          {
            Header: "Type",
            accessor: "type",
            filterable: true,
            Cell: cellProps => {
              return (
                  <div>
                    <p>
                      {cellProps.value}
                    </p>
                  </div>
                )
            },
          },
          {
            Header: "Actions",
            accessor: "id",
            filterable: true,
            Cell: cellProps => {
              let local = JSON.parse(localStorage.getItem('authUser'));
              let role = local.map((e)=>e.role);
      
                return (
                    <ul className="list-unstyled hstack gap-1 mb-0">
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                <Button  
                                    id={`${cellProps.value}`}
                                    onClick={()=>toggleRightCanvas(cellProps.value, 'allowance')}
                                    className="btn btn-sm btn-soft-primary">
                                    
                                    <i className="mdi mdi-eye-outline" id="viewtooltips"></i>
                                  </Button>
                                </li>
                                <UncontrolledTooltip placement="top" target="viewtooltips">
                                  View
                                </UncontrolledTooltip>
                              
                                <li>
                                  <Button
                                    disabled={role == 'user' ? true : false}
                                    className="btn btn-sm btn-soft-danger"
                                    // onClick={()=>ProcessOnChangeStatusAdv(cellProps.value,'rejected')}
                                  >
                                    <i className="mdi mdi-close" id="deletetooltipsss" />
                                    <UncontrolledTooltip placement="top" target="deletetooltipsss">
                                      Delete
                                    </UncontrolledTooltip>
                                  </Button>
                                </li>
                              </ul>
                  
                  )
               
            
            },
          },

        ],
        []
      )
  const  fetchSingleAdvanceSalary = (passing) =>{
    setSingleAdvanceSalaryData({
        advance_id: '',
        emp_id:{label: '', value: ''},
        adv_amount: '',
        adv_currency: {label: '', value: ''},
        adv_date: '',
        comments:''
      })
    let form = new FormData();
    form.append('method','fetch-single-adv-salary');
    form.append('advance_id' , passing);
    const requestOptions = {
        method: "POST",
        body: form,
        redirect: "follow"
      };
      
      fetch(process.env.REACT_APP_LEAVE_API_URL+"/salary.php", requestOptions)
        .then((response) => response.json())
        .then((result) => {
            result.map((e)=>{
                setSingleAdvanceSalaryData({
                    advance_id: e.id,
                    emp_id:{label: e.emp_id + '-' + e.fullname, value: e.emp_id},
                    adv_amount: e.amount,
                    adv_currency: {label: e.currency, value: e.currency},
                    adv_date: e.issue_date,
                    comments:e.comments
                  })
            })
        })
        .catch((error) => console.error(error));

  }
  const  fetchSingleLoan = (passing) =>{
    setSingleLoanData({
        loan_id: '',
        emp_id:{label: '', value: ''},
        loan_amount: '',
        loan_currency: {label: '', value: ''},
        loan_date: '',
        comments:''
      })
    let form = new FormData();
    form.append('method','fetch-single-loan');
    form.append('loan_id' , passing);
    const requestOptions = {
        method: "POST",
        body: form,
        redirect: "follow"
      };
      
      fetch(process.env.REACT_APP_LEAVE_API_URL+"/loan.php", requestOptions)
        .then((response) => response.json())
        .then((result) => {
            result.map((e)=>{
                
                setSingleLoanData({
                    loan_id : e.id,
                    emp_id: {label : e.emp_id+'-'+e.fullname , value : e.emp_id},
                    loan_amount: e.loan_amount,
                    loan_currency: {label: e.loan_currency, value: e.loan_currency},
                    comments: e.comments,
                    loan_date: e.loan_date
                })
            })
        })
        .catch((error) => console.error(error));

  
    }
  const  fetchSingleAllowance = (passing) =>{
    setSingleAllowanceData({
        allowance_id: '',
        emp_id:{label: '', value: ''},
        amount: '',
        currency: {label: '', value: ''},
        issue_date: '',
        allowance_include: '',
        allowance_type:{label: '', value: ''},
        in_salary : '',
      })
    let form = new FormData();
    form.append('method','fetch-single-allowance');
    form.append('allowance_id' , passing);
    const requestOptions = {
        method: "POST",
        body: form,
        redirect: "follow"
      };
      
      fetch(process.env.REACT_APP_LEAVE_API_URL+"/loan.php", requestOptions)
        .then((response) => response.json())
        .then((result) => {
            result.map((e)=>{
                setSingleAllowanceData({
                    allowance_id : e.id,
                    emp_id: {label : e.emp_id+'-'+e.fullname , value : e.emp_id},
                    amount: e.amount,
                    currency: {label: e.currency, value: e.currency},
                    issue_date: e.issue_date,
                    allowance_type: {label: e.type,value: e.type},
                    allowance_include : e.include_type,
                    in_salary : e.in_salary
                })
            })
        })
        .catch((error) => console.error(error));

  
    }
  const toggleTab = tab => {
    if (activeTab !== tab) {
        if(tab == "1"){
            fetchLoanFor()
            setActiveTab(tab)
        }else{
            fetchAdvanceFor();
            setActiveTab(tab)
        }
    }
  }

  const fetchEmployeeList = () => {
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let emp = localArray.map(e => e.user_id)
    let org = localArray.map(e => e.org_id)
    let leave = localArray.map(e => e.leave_right)
    let form = new FormData()
    form.append("method", "fetch-user-for-select")
    form.append("emp_id", emp)
    form.append("right", leave)
    form.append("org_id", org)
    var requestOptions = {
      method: "POST",
      body: form,
    }
    fetch(process.env.REACT_APP_LEAVE_API_URL+"/login.php", requestOptions)
      .then(res => res.json())
      .then(result => {
        console.log(result)
        setmockData(result)
      })
      .catch(er => {
        console.log(er)
      })
  }

const fetchLoanFor = () =>{
    let local = JSON.parse(localStorage.getItem('authUser'));
    let org = local.map((e)=>e.org_id);
    let emp_id = local.map((e)=>e.user_id);
        let formdata = new FormData();
        formdata.append("method", "fetch-loan");
        formdata.append("org_id", org);
        formdata.append("emp_id", emp_id);
        const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
        };

        fetch(process.env.REACT_APP_LEAVE_API_URL+"/loan.php", requestOptions)
        .then((response) => response.json())
        .then((result) => {
            setLoanData(result);
        })
        .catch((error) => console.error(error));
}
const fetchAdvanceFor = () =>{
    let local = JSON.parse(localStorage.getItem('authUser'));
    let org = local.map((e)=>e.org_id);
    let emp_id = local.map((e)=>e.user_id);
        let formdata = new FormData();
        formdata.append("method", "fetch-adv-salary");
        formdata.append("org_id", org);
        formdata.append("emp_id", emp_id);
        const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
        };

        fetch(process.env.REACT_APP_LEAVE_API_URL+"/salary.php", requestOptions)
        .then((response) => response.json())
        .then((result) => {
            setAdvanceData(result);
        })
        .catch((error) => console.error(error));
}
const fetchAllowanceFor = () =>{
    let local = JSON.parse(localStorage.getItem('authUser'));
    let org = local.map((e)=>e.org_id);
    let emp_id = local.map((e)=>e.user_id);
        let formdata = new FormData();
        formdata.append("method", "fetch-allowance");
        formdata.append("org_id", org);
        formdata.append("emp_id", emp_id);
        const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
        };

        fetch(process.env.REACT_APP_LEAVE_API_URL+"/loan.php", requestOptions)
        .then((response) => response.json())
        .then((result) => {
            setAllowanceData(result);
        })
        .catch((error) => console.error(error));
}

const toggleRightCanvas = (passingid,source) => {
    setIsRight(!isRight);
    setSource(source)
    if(source == 'advance'){
        fetchSingleAdvanceSalary(passingid)
    }else if(source == 'loan'){
         fetchSingleLoan(passingid)
    }else{
      fetchSingleAllowance(passingid)
    }
};
const [SingleLoanDataFrom , setSingleLoanDataFrom] = useState([])
const [SingleAdvDataFrom , setSingleAdvDataFrom] = useState([])
const fetchSingleLoanFromf = (id)=>{

let formdata = new FormData();
formdata.append("method", "fetch-loan-by-selected-employee");
formdata.append("emp_id", id.toString());

const requestOptions = {
  method: "POST",
  body: formdata,
  redirect: "follow"
};

fetch(process.env.REACT_APP_LEAVE_API_URL+"/loan.php", requestOptions)
  .then((response) => response.json())
  .then((result) => {
    
    setSingleLoanDataFrom(result);
  })
  .catch((error) => console.error(error));
}
const fetchSingleAdvFrom = (id)=>{

let formdata = new FormData();
formdata.append("method", "fetch-salary-by-selected-employee");
formdata.append("emp_id", id.toString());

const requestOptions = {
  method: "POST",
  body: formdata,
  redirect: "follow"
};

fetch(process.env.REACT_APP_LEAVE_API_URL+"/salary.php", requestOptions)
  .then((response) => response.json())
  .then((result) => {
    
    setSingleAdvDataFrom(result);
  })
  .catch((error) => console.error(error));
}
useEffect(()=>{
       const array = selectorgCat.map((e)=>e.value)
        if(activeTab == "1"){
            fetchSingleLoanFromf(array)
        }else if(activeTab == '2'){
            fetchSingleAdvFrom(array)
        }else if(activeTab == '3'){
          fetchSingleAllowance
        }
    
},[selectorgCat])
const [modal_center,setmodal_center ] = useState(false)
const openModal  = (param) =>{
  setModalType(param);
  setmodal_center(true)
}

  return (
    <React.Fragment>
      <div className="page-content">
        
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="6">
              <Nav tabs className="nav-tabs-custom" role="tablist">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggleTab("1")
                    }}
                  >
                    Loan
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggleTab("2")
                    }}
                  >
                    Advance Salary
                  </NavLink>
                </NavItem>
           {
            JSON.parse(localStorage.getItem('authUser')).map((e,index)=>{
              if(e.role !== 'user'){
                return (

               <NavItem key={index}>
                  <NavLink
                    className={classnames({
                      active: activeTab === "3",
                    })}
                    onClick={() => {
                      toggleTab("3")
                    }}
                  >
                    Allowance 
                  </NavLink>
                </NavItem>
                );
              }
            })
           }
                {/* <NavItem> 
                  <NavLink
                    className={classnames({
                      active: activeTab === "4",
                    })}
                    onClick={() => {
                      toggleTab("4")
                    }}
                  >
                    API Credentials
                  </NavLink>
                </NavItem> */}
              </Nav>
            </Col>
          </Row>

          <Row className="pt-3">
            <Col lg="12">
              <TabContent activeTab={activeTab} className="p-3">
                <TabPane tabId="1">
                  <Row className="mt-3 mb-3">
                    <Col>
                    <button className="btn btn-primary" onClick={()=>openModal('loan')}>Request Loan</button>
                    </Col>
                  </Row>
                
                  <Row>
                    <Col md={12}>
                      <Card>
                        <CardBody>
                           <TableContainer
                                    columns={columns}
                                    data={loanData}
                                    isGlobalFilter={true}
                                    isAddOptions={false}
                                    customPageSize={10}
                                />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                <Row className="mt-3 mb-3">
                    <Col>
                    <button className="btn btn-primary" onClick={()=>openModal('advance')}>Request Advance</button>
                    </Col>
                  </Row>
                
               
                  <Row>
                    <Col md={12}>
                      <Card>
                        <CardBody>
                          <TableContainer
                                    columns={columnsAdvance}
                                    data={AdvanceData}
                                    isGlobalFilter={true}
                                    isAddOptions={false}
                                    customPageSize={10}
                                />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                 <Row className="mt-3 mb-3">
                    <Col>
                    <button className="btn btn-primary" onClick={()=>openModal('allowance')}>Add Allowance</button>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Card>
                        <CardBody>
                          <TableContainer
                                    columns={columnsAllowance}
                                    data={AllowanceData}
                                    isGlobalFilter={true}
                                    isAddOptions={false}
                                    customPageSize={10}
                                />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
                    <Modal
                      isOpen={modal_center}
                      toggle={() => {
                        tog_center();
                      }}
                      centered
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0">
                       {
                       ModalType === 'loan' ? 'Loan Information' :
                       ModalType === 'advance' ? 'Advance Information' :
                       ModalType === 'allowance' ? 'Allowance Information' :
                       ''

                       }
                        </h5>
                        <button
                          type="button"
                          onClick={() => {
                            setmodal_center(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      <Row>
                    <Col xl="12">
                      <Card>
                        <CardBody>
                         
                          {ModalType == 'loan' ? 
                          <Form
                            onSubmit={e => {
                              e.preventDefault()
                             
                              const values = selectorgCat.map(e => e.value)
                              console.log(values)
                              if (selectorgCat.length == 0) {
                                toastr.error("Select Employee")
                                return false
                              } else if (
                                e.target.elements.loan_amount.value == ""
                              ) {
                                toastr.error("Loan amount is required")
                                return false
                              } else if (
                                e.target.elements.issue_date.value == ""
                              ) {
                                toastr.error("Issue Date is required")
                                return false
                              } else {
                                setSpinnerForLoan(true)
                                
                                let form  = new FormData();
                                let local = JSON.parse(localStorage.getItem('authUser'));
                                let org = local.map((e)=>e.org_id);
                                form.append('org_id',org);
                                form.append('method' , 'add-loan')
                                form.append('emp_ids' , values.toString());
                                form.append('loan_amount' ,e.target.elements.loan_amount.value );
                                form.append('loan_currency' ,e.target.elements.loan_currency.value  );
                                form.append('loan_date' ,e.target.elements.issue_date.value );
                                form.append('refund_cycle' ,'1');
                                form.append('amount_per_cycle' ,e.target.elements.loan_amount.value);
                                form.append('comments' ,e.target.elements.comments.value );
                                const requestOptions = {
                                    method: "POST",
                                    body: form,
                                    redirect: "follow"
                                  };
                                  
                                  fetch(process.env.REACT_APP_LEAVE_API_URL+"/loan.php", requestOptions)
                                    .then((response) => response.text())
                                    .then((result) => {
                                        setSpinnerForLoan(false)
                                        if(result== 'success'){
                                            e.target.reset();
                                            setLoanAmount('')
                                            setPerCycleAmount('')
                                            setNoofCycles('')
                                            setSelectOrgCat([])
                                            e.target.elements.loan_currency.value = "";
                                            Swal.fire('You successfully requested for a loan', 'We will informed you when administration will approved the loan').then((is)=>{
                                                if(is.isConfirmed){
                                                    fetchLoanFor()
                                                }
                                            });

                                        }else{
                                            Swal.fire('Server Error', 'Check your input fields are try again');
                                        }
                                    })
                                    .catch((error) => console.error(error))
                                // api.frontforcecrm.com/loan.php
                              }
                            }}
                          >
                            <Row className="mb-4">
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                Select Employee
                              </Label>
                              <Col sm={9}>
                                <Select
                                  value={selectorgCat}
                                  options={mockData}
                                  isMulti
                                  onChange={e => {
                                   
                                    setSelectOrgCat(e)}}
                                  className="select2-selection"
                                  placeholder="Select Employee"
                                />
                              </Col>
                            </Row>

                            <Row className="mb-4">
                              <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                              >
                                Enter Loan Amount
                              </Label>
                              <Col sm={9}>
                                <Input
                                  type="number"
                                  min={1}
                                  className="form-control"
                                  name="loan_amount"
                                  value={loanAmount}
                                  onChange={e => setLoanAmount(e.target.value)}
                                  id="horizontal-firstname-Input"
                                  placeholder="Loan Amount"
                                />
                              </Col>
                            </Row>
                            <Row className="mb-4">
                              <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                              >
                                Amount Currency
                              </Label>
                              <Col sm={9}>
                                <Select
                                  options={[
                                    {label:'PKR' , value: 'PKR'},
                                    {label:'USD' , value: 'USD'}
                                  ]}
                                  name="loan_currency"
                                  className="select2-selection"
                                  placeholder="Select Currency"
                                />
                              </Col>
                            </Row>
                            <Row className="mb-4">
                              <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                              >
                                Issue Date
                              </Label>
                              <Col sm={9}>
                                <Input
                                  type="date"
                                  name="issue_date"
                                  //   value={orgName}
                                  //   onChange={e => setOrgName(e.target.value)}
                                  className="form-control"
                                  id="horizontal-firstname-Input"
                                  placeholder="Select Date"
                                />
                              </Col>
                            </Row>
                            {/* <Row className="mb-4">
                              <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                              >
                                Refund Cycle
                              </Label>
                              <Col sm={9}>
                                <Input
                                  type="number"
                                  name="refund_Cycle"
                                  min={1}
                                  value={noOfCycles}
                                  onChange={e => onchangeValueOfCycle(e.target.value)}
                                  className="form-control"
                                  id="horizontal-firstname-Input"
                                  placeholder="Refund Cycle"
                                />
                              </Col>
                            </Row> */}
                            {/* <Row className="mb-4">
                              <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                              >
                                Refund Amount Per Cycle
                              </Label>
                              <Col sm={9}>
                                <Input
                                  type="number"
                                  name="refund_amount"
                                  min={1}
                                  disabled
                                  value={perCycleAmount}
                                  //   value={orgName}
                                  //   onChange={e => setOrgName(e.target.value)}
                                  className="form-control"
                                  id="horizontal-firstname-Input"
                                  placeholder="Amount per cycle"
                                />
                              </Col>
                            </Row> */}
                            <Row className="mb-4">
                              <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                              >
                                Comments
                              </Label>
                              <Col sm={9}>
                                <Input
                                  type="textarea"
                                  name="comments"
                                  //   value={orgName}
                                  //   onChange={e => setOrgName(e.target.value)}
                                  className="form-control"
                                  id="horizontal-firstname-Input"
                                  placeholder="Comments...."
                                />
                              </Col>
                            </Row>
                            {/* <Row className="mb-4">
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                Country 
                              </Label>
                              <Col sm={9}>
                                <Select
                                  value="Select"
                                  options={optionGroup}
                                  className="select2-selection"
                                  placeholder="Select country or region"
                                />
                              </Col>
                            </Row> */}

                            <Row className="float-end mb-2">
                              <Col>
                                <div>
                                  {spinnerForLoan == true ? (
                                    <Spinner size={"md"} />
                                  ) : (
                                    <Button
                                      type="submit"
                                      color="primary"
                                      className="w-md"
                                    >
                                      Request Loan
                                    </Button>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </Form> : ModalType == 'advance' ? 
                             <Form  
                             onSubmit={(e)=>{
                               e.preventDefault();
                               const values = selectorgCat.map(e => e.value)
                               console.log(values)
                               if (selectorgCat.length == 0) {
                                 toastr.error("Select Employee")
                                 return false
                               } else if (
                                 e.target.elements.advance_amount.value == ""
                               ) {
                                 toastr.error("Advance amount is required")
                                 return false
                               } else if (
                                 e.target.elements.advance_issue_date.value == ""
                               ) {
                                 toastr.error("Issue Date is required")
                                 return false
                               }  else {
                                 setSpinner(true)
                                 
                                 let form  = new FormData();
                                 let local = JSON.parse(localStorage.getItem('authUser'));
                                 let org = local.map((e)=>e.org_id);
                                 form.append('org_id',org);
                                 form.append('method' , 'add-advance')
                                 form.append('emp_id' , values.toString());
                                 form.append('amount' ,e.target.elements.advance_amount.value );
                                 form.append('currency' ,e.target.elements.advance_currency.value  );
                                 form.append('issue_date' ,e.target.elements.advance_issue_date.value );
                                 form.append('remarks' ,e.target.elements.comments_for_advance.value );
                                 const requestOptions = {
                                     method: "POST",
                                     body: form,
                                     redirect: "follow"
                                   };
                                   
                                   fetch(process.env.REACT_APP_LEAVE_API_URL+"/salary.php", requestOptions)
                                     .then((response) => response.text())
                                     .then((result) => {
                                         setSpinner(false)
                                         if(result== 'success'){
                                           setSelectOrgCat([])
                                           e.target.elements.advance_amount.value = ""
                                           e.target.elements.advance_currency.value = "" 
                                           e.target.elements.advance_issue_date.value = ""
                                           e.target.elements.comments_for_advance.value =""
                                             Swal.fire('You successfully requested for a Advance Salary', 'We will informed you when administration will approved the loan').then((is)=>{
                                               if(is.isConfirmed){
                                                   fetchAdvanceFor()
                                               }
                                           });
   
                                         }else{
                                             Swal.fire('Server Error', 'Check your input fields are try again');
                                         }
                                     })
                                     .catch((error) => console.error(error))
                                 // api.frontforcecrm.com/loan.php
                               }
                               
                             }}
                             
                             >
                               <Row className="mb-4">
                                 <Label
                                   htmlFor="horizontal-email-Input"
                                   className="col-sm-3 col-form-label"
                                 >
                                   Select Employee
                                 </Label>
                                 <Col sm={9}>
                                   <Select
                                     value={selectorgCat}
                                     options={mockData}
                                     isMulti
                                     onChange={e => setSelectOrgCat(e)}
                                     className="select2-selection"
                                     placeholder="Select Employee"
                                   />
                                 </Col>
                               </Row>
   
                               <Row className="mb-4">
                                 <Label
                                   htmlFor="horizontal-firstname-Input"
                                   className="col-sm-3 col-form-label"
                                 >
                                   Enter Amount
                                 </Label>
                                 <Col sm={9}>
                                   <Input
                                     type="number"
                                     min={1}
                                     name="advance_amount"
                                     className="form-control"
                                     id="horizontal-firstname-Input"
                                     placeholder="Advance Amount"
                                   />
                                 </Col>
                               </Row>
                               <Row className="mb-4">
                                 <Label
                                   htmlFor="horizontal-firstname-Input"
                                   className="col-sm-3 col-form-label"
                                 >
                                   Amount Currency
                                 </Label>
                                 <Col sm={9}>
                                   <Select
                                    
                                     options={[
                                       {label:'PKR' , value: 'PKR'},
                                       {label:'USD' , value: 'USD'}
                                     ]}
                                     name="advance_currency"
                                     className="select2-selection"
                                     placeholder="Select Currency"
                                   />
                                 </Col>
                               </Row>
                               <Row className="mb-4">
                                 <Label
                                   htmlFor="horizontal-firstname-Input"
                                   className="col-sm-3 col-form-label"
                                 >
                                   Issue Date
                                 </Label>
                                 <Col sm={9}>
                                   <Input
                                     type="date"
                                     name="advance_issue_date"
                                     className="form-control"
                                     id="horizontal-firstname-Input"
                                     placeholder="Select Date"
                                   />
                                 </Col>
                               </Row>
   
                               <Row className="mb-4">
                                 <Label
                                   htmlFor="horizontal-firstname-Input"
                                   className="col-sm-3 col-form-label"
                                 >
                                   Comments
                                 </Label>
                                 <Col sm={9}>
                                   <Input
                                     type="textarea"
                                     name="comments_for_advance"
                                     className="form-control"
                                     id="horizontal-firstname-Input"
                                     placeholder="Comments...."
                                   />
                                 </Col>
                               </Row>
                               {/* <Row className="mb-4">
                                 <Label
                                   htmlFor="horizontal-email-Input"
                                   className="col-sm-3 col-form-label"
                                 >
                                   Country 
                                 </Label>
                                 <Col sm={9}>
                                   <Select
                                     value="Select"
                                     options={optionGroup}
                                     className="select2-selection"
                                     placeholder="Select country or region"
                                   />
                                 </Col>
                               </Row> */}
   
                               <Row className="float-end mb-2">
                                 <Col>
                                   <div>
                                     {spinner == true ? (
                                       <Spinner size={"md"} />
                                     ) : (
                                       <Button
                                         type="submit"
                                         color="primary"
                                         className="w-md"
                                       >
                                         Request Advance
                                       </Button>
                                     )}
                                   </div>
                                 </Col>
                               </Row>
                             </Form> : ModalType == 'allowance' ? 
                                <Form  
                                onSubmit={(e)=>{
                                  e.preventDefault();
                                  const values = selectorgCat.map(e => e.value)
                                  console.log(values)
                                  if (selectorgCat.length == 0) {
                                    toastr.error("Select Employee")
                                    return false
                                  } else if (
                                     e.target.elements.allowance_type.value == ""
                                  ) {
                                    toastr.error("Allowance Type is required")
                                    return false
                                  } else if (
                                    e.target.elements.allowance_amount.value == ""
                                  ) {
                                    toastr.error("Allowance Amount is required")
                                    return false
                                  } else if (
                                    e.target.elements.allowance_currency.value == ""
                                  ) {
                                    toastr.error("Allowance currency is required")
                                    return false
                                  }else if (
                                    e.target.elements.allowance_include.value == ""
                                  ) {
                                    toastr.error("Select allowance include type")
                                    return false
                                  } else if (
                                    e.target.elements.allowance_issue_date.value == ""
                                  ) {
                                    toastr.error("Allowance date is required")
                                    return false
                                  }  else {
      
                                    setSpinner(true)
                                    
                                    let form  = new FormData();
                                    let local = JSON.parse(localStorage.getItem('authUser'));
                                    let org = local.map((e)=>e.org_id);
                                    form.append('org_id',org);
                                    form.append('method' , 'add-allowance')
                                    form.append('emp_id' , values.toString());
                                    form.append('org_id' , org);
                                    form.append('allowance_type' ,e.target.elements.allowance_type.value );
                                    form.append('allowance_typer' ,e.target.elements.allowance_typer.value);
                                    form.append('allowance_amount' ,e.target.elements.allowance_amount.value  );
                                    form.append('allowance_currency' ,e.target.elements.allowance_currency.value  );
                                    form.append('allowance_include' ,e.target.elements.allowance_include.value );
                                    form.append('allowance_date' ,e.target.elements.allowance_issue_date.value );
                                    const requestOptions = {
                                        method: "POST",
                                        body: form,
                                        redirect: "follow"
                                      };
                                      
                                      fetch(process.env.REACT_APP_LEAVE_API_URL+"/loan.php", requestOptions)
                                        .then((response) => response.text())
                                        .then((result) => {
                                            setSpinner(false)
                                            if(result== 'success'){
                                              setSelectOrgCat([])
                                              e.target.elements.allowance_type.value = ""
                                              e.target.elements.allowance_amount.value = "" 
                                              e.target.elements.allowance_currency.value = ""
                                              e.target.elements.allowance_include.value =""
                                              e.target.elements.allowance_issue_date.value =""
                                              e.target.elements.allowance_typer.value =""
                                                Swal.fire('Success', 'You successfully add the allowance','success').then((is)=>{
                                                  if(is.isConfirmed){
                                                      fetchAllowanceFor()
                                                  }
                                              });
      
                                            }else{
                                                Swal.fire('Server Error', 'Check your input fields are try again');
                                            }
                                        })
                                        .catch((error) => console.error(error))
                                    // api.frontforcecrm.com/loan.php
                                  }
                                  
                                }}
                                
                                >
                                  <Row className="mb-4">
                                    <Label
                                      htmlFor="horizontal-email-Input"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Select Employee
                                    </Label>
                                    <Col sm={9}>
                                      <Select
                                        value={selectorgCat}
                                        options={mockData}
                                        isMulti
                                        onChange={e => setSelectOrgCat(e)}
                                        className="select2-selection"
                                        placeholder="Select Employee"
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="mb-4">
                                    <Label
                                      htmlFor="horizontal-email-Input"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Select Allowance Type
                                    </Label>
                                    <Col sm={9}>
                                      <Select
                                        options={[
                                          {
                                              label: "Housing Allowance",
                                              value: "Housing Allowance"
                                          },
                                          {
                                              label: "Transportation Allowance",
                                              value: "Transportation Allowance"
                                          },
                                          {
                                              label: "Medical Allowance",
                                              value: "Medical Allowance"
                                          },
                                          {
                                              label: "Meal Allowance",
                                              value: "Meal Allowance"
                                          },
                                          {
                                              label: "Education Allowance",
                                              value: "Education Allowance"
                                          },
                                          {
                                              label: "Travel Allowance",
                                              value: "Travel Allowance"
                                          },
                                          {
                                              label: "Communication Allowance",
                                              value: "Communication Allowance"
                                          },
                                          {
                                              label: "Uniform Allowance",
                                              value: "Uniform Allowance"
                                          },
                                          {
                                              label: "Entertainment Allowance",
                                              value: "Entertainment Allowance"
                                          },
                                          {
                                              label: "Special Duty Allowance",
                                              value: "Special Duty Allowance"
                                          },
                                          {
                                            label: "Fuel Allowance",
                                            value: "Fuel Allowance"
                                        },
                                        {
                                            label: "Vehicle Maintenance Allowance",
                                            value: "Vehicle Maintenance_allowance"
                                        },
                                        {
                                            label: "Internet Allowance",
                                            value: "Internet Allowance"
                                        },
                                        {
                                            label: "Cell Phone Allowance",
                                            value: "Cell Phone Allowance"
                                        },
                                        {
                                            label: "Training Allowance",
                                            value: "Training Allowance"
                                        }
                                      ]}
                                        name="allowance_type"
                                        className="select2-selection"
                                        placeholder="Select Employee"
                                      />
                                    </Col>
                                  </Row>
      
                                  <Row className="mb-4">
                                    <Label
                                      htmlFor="horizontal-firstname-Input"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Enter Amount
                                    </Label>
                                    <Col sm={9}>
                                      <Input
                                        type="number"
                                        min={1}
                                        name="allowance_amount"
                                        className="form-control"
                                        id="horizontal-firstname-Input"
                                        placeholder="Amount"
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="mb-4">
                                    <Label
                                      htmlFor="horizontal-firstname-Input"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Amount Currency
                                    </Label>
                                    <Col sm={9}>
                                      <Select
                                        options={[
                                          {label:'PKR' , value: 'PKR'},
                                          {label:'USD' , value: 'USD'}
                                        ]}
                                        name="allowance_currency"
                                        className="select2-selection"
                                        placeholder="Select Currency"
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="mb-4">
                                    <Label
                                      htmlFor="horizontal-firstname-Input"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Allowance Include ?
                                    </Label>
                                    <Col sm={9} className="mt-2">
                                      <Input
                                        type="radio"
                                        name="allowance_include"
                                        id="horizontal-firstname-Inputss"
                                        placeholder="Select Date"
                                        value={'once'}
                                      /> <label htmlFor="horizontal-firstname-Inputss">Once</label> 
                                      <Input
                                        style={{marginLeft:10}}
                                        type="radio"
                                        name="allowance_include"
                                        id="horizontal-firstname-Inputs"
                                        placeholder="Select Date"
                                        value={'per_month'}
                                      /> <label htmlFor="horizontal-firstname-Inputs">Per Month</label> 
                                    </Col>
                                  </Row>
                                  <Row className="mb-4">
                                    <Label
                                      htmlFor="horizontal-firstname-Input"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Select Option
                                    </Label>
                                    <Col sm={9} className="mt-2">
                                    <Input
                                        
                                        type="radio"
                                        name="allowance_typer"
                                        id="horizontal-firstname-Input2"
                                        value={'addon'}
                                      /><label htmlFor="horizontal-firstname-Input2">Addon</label> 
                                      <Input
                                        type="radio"
                                        style={{marginLeft:10}}
                                        name="allowance_typer"
                                        id="horizontal-firstname-Input1"
                                        value={'deduct'}
                                      /> <label htmlFor="horizontal-firstname-Input1">Deduct</label> 
                                     
                                    </Col>
                                  </Row>
                                  <Row className="mb-4">
                                    <Label
                                      htmlFor="horizontal-firstname-Input"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Issue Date
                                    </Label>
                                    <Col sm={9}>
                                      <Input
                                        type="date"
                                        name="allowance_issue_date"
                                        className="form-control"
                                        id="horizontal-firstname-Input"
                                        placeholder="Select Date"
                                      />
                                    </Col>
                                  </Row>
      
                                  
                                  {/* <Row className="mb-4">
                                    <Label
                                      htmlFor="horizontal-email-Input"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Country 
                                    </Label>
                                    <Col sm={9}>
                                      <Select
                                        value="Select"
                                        options={optionGroup}
                                        className="select2-selection"
                                        placeholder="Select country or region"
                                      />
                                    </Col>
                                  </Row> */}
      
                                  <Row className="float-end mb-2">
                                    <Col>
                                      <div>
                                        {spinner == true ? (
                                          <Spinner size={"md"} />
                                        ) : (
                                          <Button
                                            type="submit"
                                            color="primary"
                                            className="w-md"
                                          >
                                            Add Allowance
                                          </Button>
                                        )}
                                      </div>
                                    </Col>
                                  </Row>
                                </Form> : ''

                          }
                           
                        </CardBody>
                      </Card>
                      
                    </Col>
                    {/* <Col xl="4">
                    {
                  SingleLoanDataFrom.map((e,index)=>(
                    <Row key={index}>
                      <Col md={2}  style={{
                        display:'grid',
                        alignContent:'center',
                        marginRight:15

                      }}>
                        <img
                        src={e.profile}
                        className="avatar-md rounded-circle"
                        />
                      </Col>
                      <Col md={8} className="myclass_iwant">
                       <p><span>ID</span> {e.emp_id}</p>
                       <p><span>Name </span> {e.fullname}</p>
                       <p><span>Designation</span> {e.designation}</p>
                       <p><span>Department</span> {e.department}</p>
                       <p><span>Remaining Balance</span>  { e.currency !=  null ?formatAmount(parseFloat(e.prev_balance) , e.currency) : null}</p>
                        
                      </Col>
                      <Col md={12}>
                      <Row>
                        <Col md={6} className="myclass_iwant">
                        
                        </Col>
                        
                      </Row>
                      </Col>
                    </Row>
                  ))
                }
                    </Col> */}
                  </Row>
                      </div>
                    </Modal>
           <Offcanvas
                                        isOpen={isRight}
                                        style={{width:600}}
                                        direction="end"
                                        toggle={()=>setIsRight(!isRight)}>
                                        <OffcanvasHeader toggle={()=>setIsRight(!isRight)}>
                                            {source == 'loan' ?  'Edit Loan' : null}
                                            {source == 'advance' ?  'Edit Advance' : null}
                                            {source == 'allowance' ?  'Edit Allowance' : null}
                                        </OffcanvasHeader>
                                        <OffcanvasBody>
                                            {
                                                source == 'advance' ? 
                                                <Form  
                                                onSubmit={(e)=>{
                                                  e.preventDefault();
                                                 
                                                    setSpinner(true)
                                                    
                                                    let form  = new FormData();
                                                    
                                                    form.append('method' , 'advance-salary-update-by-id')
                                                    form.append('emp_id' , SingleAdvanceSalaryData.emp_id.value);
                                                    form.append('advance_id' , SingleAdvanceSalaryData.advance_id);
                                                    form.append('amount' , SingleAdvanceSalaryData.adv_amount );
                                                    form.append('currency' ,SingleAdvanceSalaryData.adv_currency.value  );
                                                    form.append('issue_date' ,SingleAdvanceSalaryData.adv_date);
                                                    form.append('remarks' ,SingleAdvanceSalaryData.comments);
                                                    const requestOptions = {
                                                        method: "POST",
                                                        body: form,
                                                        redirect: "follow"
                                                      };
                                                      
                                                      fetch(process.env.REACT_APP_LEAVE_API_URL+"/salary.php", requestOptions)
                                                        .then((response) => response.text())
                                                        .then((result) => {
                                                            setSpinner(false)
                                                            if(result== 'success'){
                                                               
                                                                Swal.fire('Save Changes', 'You successfully update the advance salary').then((is)=>{
                                                                  if(is.isConfirmed){
                                                                      fetchAdvanceFor()
                                                                  }
                                                              });
                      
                                                            }else{
                                                                Swal.fire('Server Error', 'Check your input fields are try again');
                                                            }
                                                        })
                                                        .catch((error) => console.error(error))
                                                    // api.frontforcecrm.com/loan.php
                                                //   }
                                                  
                                                }}
                                                
                                                >
                                                  <Row className="mb-4">
                                                  <Col md={12} style={{textAlign:'center'}}>
                                                    {
                                                        SingleAdvanceSalaryData.advance_id == "" ? 
                                                        <Spinner color="info"/> : null    
                                                    }
                                                </Col>
                                                    <Label
                                                      htmlFor="horizontal-email-Input"
                                                      className="col-sm-12 col-form-label"
                                                    >
                                                      Select Employee
                                                    </Label>
                                                    <Col sm={12}>
                                                      <Select
                                                         isDisabled={role !=  'admin'? true:false}
                                                          value={SingleAdvanceSalaryData.emp_id}
                                                          options={mockData}
                                                          onChange={e => setSingleAdvanceSalaryData(prevState =>({
                                                             ...prevState,
                                                             emp_id: e
                                                          }))}
                                                       className="select2-selection"
                                                        placeholder="Select Employee"
                                                      />
                                                    </Col>
                                                  </Row>
                      
                                                  <Row className="mb-4">
                                                    <Label
                                                      htmlFor="horizontal-firstname-Input"
                                                      className="col-sm-12 col-form-label"
                                                    >
                                                      Enter Amount
                                                    </Label>
                                                    <Col sm={12}>
                                                      <Input
                                                        type="number"
                                                        disabled={role !=  'admin'? true:false}
                                                        min={1}
                                                        value={parseFloat(SingleAdvanceSalaryData.adv_amount)}
                                                        
                                                        onChange={e => setSingleAdvanceSalaryData(prevState =>({
                                                           ...prevState,
                                                           adv_amount: e.target.value
                                                        }))}
                                                        name="advance_amount"
                                                        className="form-control"
                                                        id="horizontal-firstname-Input"
                                                        placeholder="Advance Amount"
                                                      />
                                                    </Col>
                                                  </Row>
                                                  <Row className="mb-4">
                                                    <Label
                                                      htmlFor="horizontal-firstname-Input"
                                                      className="col-sm-12 col-form-label"
                                                    >
                                                      Amount Currency
                                                    </Label>
                                                    <Col sm={12}>
                                                      <Select
                                                       value={SingleAdvanceSalaryData.adv_currency}
                                                       disabled={role !=  'admin'? true:false}
                                                       onChange={e => setSingleAdvanceSalaryData(prevState =>({
                                                          ...prevState,
                                                          adv_currency: e
                                                       }))}
                                                        options={[
                                                          {label:'PKR' , value: 'PKR'},
                                                          {label:'USD' , value: 'USD'}
                                                        ]}
                                                        name="advance_currency"
                                                        className="select2-selection"
                                                        placeholder="Select Currency"
                                                      />
                                                    </Col>
                                                  </Row>
                                                  <Row className="mb-4">
                                                    <Label
                                                      htmlFor="horizontal-firstname-Input"
                                                      className="col-sm-12 col-form-label"
                                                    >
                                                      Issue Date
                                                    </Label>
                                                    <Col sm={12}>
                                                      <Input
                                                        type="date"
                                                        value={SingleAdvanceSalaryData.adv_date}
                                                        disabled={role !=  'admin'? true:false}
                                                        onChange={e => setSingleAdvanceSalaryData(prevState =>({
                                                           ...prevState,
                                                           adv_date: e.target.value
                                                        }))}
                                                        name="advance_issue_date"
                                                        className="form-control"
                                                        id="horizontal-firstname-Input"
                                                        placeholder="Select Date"
                                                      />
                                                    </Col>
                                                  </Row>
                      
                                                  <Row className="mb-4">
                                                    <Label
                                                      htmlFor="horizontal-firstname-Input"
                                                      className="col-sm-12 col-form-label"
                                                    >
                                                      Comments
                                                    </Label>
                                                    <Col sm={12}>
                                                      <Input
                                                        type="textarea"
                                                        disabled={role !=  'admin'? true:false}
                                                        value={SingleAdvanceSalaryData.comments}
                                                        
                                                        onChange={e => setSingleAdvanceSalaryData(prevState =>({
                                                           ...prevState,
                                                           comments: e.target.value
                                                        }))}
                                                        name="comments_for_advance"
                                                        className="form-control"
                                                        id="horizontal-firstname-Input"
                                                        placeholder="Comments...."
                                                      />
                                                    </Col>
                                                  </Row>
                                                  {/* <Row className="mb-4">
                                                    <Label
                                                      htmlFor="horizontal-email-Input"
                                                      className="col-sm-3 col-form-label"
                                                    >
                                                      Country 
                                                    </Label>
                                                    <Col sm={9}>
                                                      <Select
                                                        value="Select"
                                                        options={optionGroup}
                                                        className="select2-selection"
                                                        placeholder="Select country or region"
                                                      />
                                                    </Col>
                                                  </Row> */}
                      
                                                  <Row className="float-end mb-2">
                                                    <Col>
                                                      <div>
                                                        {spinner == true ? (
                                                          <Spinner size={"md"} />
                                                        ) : (
                                                          <Button
                                                            type="submit"
                                                            color="primary"
                                                            className="w-md"
                                                            disabled={role !=  'admin'? true:false}
                                                          >
                                                            Request Advance
                                                          </Button>
                                                        )}
                                                      </div>
                                                    </Col>
                                                  </Row>
                                                </Form>
                                               :null}
                                              {source == 'loan'? 
                                               <Form
                                               onSubmit={e => {
                                                 e.preventDefault()
                                                
                                               
                                                   setSpinnerForLoan(true)
                                                   
                                                   let form  = new FormData();
                                                   let local = JSON.parse(localStorage.getItem('authUser'));
                                                   let org = local.map((e)=>e.org_id);
                                                   form.append('org_id',org);
                                                   form.append('method' , 'update-loan-by-id')
                                                   form.append('emp_id' , SingleLoanData.emp_id.value);
                                                   form.append('loan_id' , SingleLoanData.loan_id);
                                                   form.append('loan_amount' ,SingleLoanData.loan_amount);
                                                   form.append('loan_currency' ,SingleLoanData.loan_currency.value);
                                                   form.append('loan_date' ,SingleLoanData.loan_date);
                                                   form.append('loan_comments' ,SingleLoanData.comments);
                                                   const requestOptions = {
                                                       method: "POST",
                                                       body: form,
                                                       redirect: "follow"
                                                     };
                                                     
                                                     fetch(process.env.REACT_APP_LEAVE_API_URL+"/loan.php", requestOptions)
                                                       .then((response) => response.text())
                                                       .then((result) => {
                                                           setSpinnerForLoan(false)
                                                           if(result== 'success'){
                                                               Swal.fire('Change Done', 'You successfully save the changes').then((is)=>{
                                                                   if(is.isConfirmed){
                                                                       fetchLoanFor()
                                                                   }
                                                               });
                   
                                                           }else{
                                                               Swal.fire('Server Error', 'Check your input fields are try again');
                                                           }
                                                       })
                                                       .catch((error) => console.error(error))
                                                   // api.frontforcecrm.com/loan.php
                                                //  }
                                               }}
                                             >
                                               <Row className="mb-4">
                                               <Col md={12} style={{textAlign:'center'}}>
                                                {
                                                    SingleLoanData.loan_id == "" ? 
                                                    <Spinner color="info"/> : null    
                                                }
                                               </Col>
                                               <Col sm={12}>
                                                 <Label
                                                   htmlFor="horizontal-email-Input"
                                                   className="col-sm-12 col-form-label"
                                                 >
                                                   Select Employee
                                                 </Label>
                                                 </Col>
                                                 <Col sm={12}>
                                                   <Select
                                                    isDisabled={role !=  'admin'? true:false}
                                                     value={SingleLoanData.emp_id}
                                                     options={mockData}
                                                     onChange={e => setSingleLoanData(prevState =>({
                                                        ...prevState,
                                                        emp_id: e
                                                     }))}
                                                     className="select2-selection"
                                                     placeholder="Select Employee"
                                                   />
                                                 </Col>
                                               </Row>
                   
                                               <Row className="mb-4">
                                               <Col sm={12}>
                                                 <Label
                                                   htmlFor="horizontal-firstname-Input"
                                                   className="col-md-12 col-form-label"
                                                 >
                                                   Enter Loan Amount
                                                 </Label>
                                                 </Col>
                                                 <Col sm={12}>
                                                   <Input
                                                     type="number"
                                                     min={1}
                                                     className="form-control"
                                                     name="loan_amount"
                                                     disabled={role !=  'admin'? true:false}
                                                     value={SingleLoanData.loan_amount}
                                                     onChange={e => setSingleLoanData(prevState =>({
                                                        ...prevState,
                                                        loan_amount: e.target.value
                                                     }))}
                                                     id="horizontal-firstname-Input"
                                                     placeholder="Loan Amount"
                                                   />
                                                 </Col>
                                               </Row>
                                               <Row className="mb-4">
                                                <Col md={12}>
                                                 <Label
                                                   htmlFor="horizontal-firstname-Input"
                                                   className="col-md-12 col-form-label"
                                                 >
                                                   Amount Currency
                                                 </Label>
                                                 </Col>
                                                 <Col sm={12}>
                                                   <Select
                                                      value={SingleLoanData.loan_currency}
                                                      
                                                      onChange={e => setSingleLoanData(prevState =>({
                                                        ...prevState,
                                                        loan_currency: e
                                                     }))}
                                                     isDisabled={role !=  'admin'? true:false}
                                                     options={[
                                                       {label:'PKR' , value: 'PKR'},
                                                       {label:'USD' , value: 'USD'}
                                                     ]}
                                                     name="loan_currency"
                                                     className="select2-selection"
                                                     placeholder="Select Currency"
                                                   />
                                                 </Col>
                                               </Row>
                                               <Row className="mb-4">
                                                <Col md={12}>

                                                 <Label
                                                   htmlFor="horizontal-firstname-Input"
                                                   className="col-md-12 col-form-label"
                                                 >
                                                   Issue Date
                                                 </Label>
                                                </Col>
                                                 <Col sm={12}>
                                                   <Input
                                                     type="date"
                                                     name="issue_date"
                                                     disabled={role !=  'admin'? true:false}
                                                     value={SingleLoanData.loan_date}
                                                     onChange={e => setSingleLoanData(prevState =>({
                                                        ...prevState,
                                                        loan_date: e.target.value
                                                     }))}
                                                     className="form-control"
                                                     id="horizontal-firstname-Input"
                                                     placeholder="Select Date"
                                                   />
                                                 </Col>
                                               </Row>
                                               {/* <Row className="mb-4">
                                                 <Label
                                                   htmlFor="horizontal-firstname-Input"
                                                   className="col-md-12 col-form-label"
                                                 >
                                                   Refund Cycle
                                                 </Label>
                                                 <Col sm={9}>
                                                   <Input
                                                     type="number"
                                                     name="refund_Cycle"
                                                     min={1}
                                                     value={noOfCycles}
                                                     onChange={e => onchangeValueOfCycle(e.target.value)}
                                                     className="form-control"
                                                     id="horizontal-firstname-Input"
                                                     placeholder="Refund Cycle"
                                                   />
                                                 </Col>
                                               </Row> */}
                                               {/* <Row className="mb-4">
                                                 <Label
                                                   htmlFor="horizontal-firstname-Input"
                                                   className="col-md-12 col-form-label"
                                                 >
                                                   Refund Amount Per Cycle
                                                 </Label>
                                                 <Col sm={9}>
                                                   <Input
                                                     type="number"
                                                     name="refund_amount"
                                                     min={1}
                                                     disabled
                                                     value={perCycleAmount}
                                                     //   value={orgName}
                                                     //   onChange={e => setOrgName(e.target.value)}
                                                     className="form-control"
                                                     id="horizontal-firstname-Input"
                                                     placeholder="Amount per cycle"
                                                   />
                                                 </Col>
                                               </Row> */}
                                               <Row className="mb-4">
                                                 <Label
                                                   htmlFor="horizontal-firstname-Input"
                                                   className="col-md-12 col-form-label"
                                                 >
                                                   Comments
                                                 </Label>
                                                 <Col sm={12}>
                                                   <Input
                                                   disabled={role !=  'admin'? true:false}
                                                     type="textarea"
                                                     name="comments"
                                                     value={SingleLoanData.comments}
                                                     onChange={e => setSingleLoanData(prevState =>({
                                                        ...prevState,
                                                        comments: e.target.value
                                                     }))}
                                                     className="form-control"
                                                     id="horizontal-firstname-Input"
                                                     placeholder="Comments...."
                                                   />
                                                 </Col>
                                               </Row>
                                               {/* <Row className="mb-4">
                                                 <Label
                                                   htmlFor="horizontal-email-Input"
                                                   className="col-sm-3 col-form-label"
                                                 >
                                                   Country 
                                                 </Label>
                                                 <Col sm={9}>
                                                   <Select
                                                     value="Select"
                                                     options={optionGroup}
                                                     className="select2-selection"
                                                     placeholder="Select country or region"
                                                   />
                                                 </Col>
                                               </Row> */}
                   
                                               <Row className="float-end mb-2">
                                                 <Col>
                                                   <div>
                                                     {spinnerForLoan == true ? (
                                                       <Spinner size={"sm"} />
                                                     ) : (
                                                       <Button
                                                         type="submit"
                                                         color="primary"
                                                         className="w-md"
                                                         disabled={role !=  'admin'? true:false}
                                                       >
                                                         Edit Loan
                                                       </Button>
                                                     )}
                                                   </div>
                                                 </Col>
                                               </Row>
                                             </Form>:null
                                               
                                            }
                                            {
                                              source == 'allowance'? 
                                                <Form  
                                              onSubmit={(e)=>{
                                                e.preventDefault();
                                                
                    
                                                  setSpinner(true)
                                                  
                                                  let form  = new FormData();
                                                  let local = JSON.parse(localStorage.getItem('authUser'));
                                                  let org = local.map((e)=>e.org_id);
                                                  form.append('org_id',org);
                                                  form.append('method' , 'update-allowance-by-id')
                                                  form.append('allowance_id' ,SingleAllowanceData.allowance_id);
                                                  form.append('emp_id' ,SingleAllowanceData.emp_id.value);
                                                  form.append('allowance_type' ,SingleAllowanceData.allowance_type.label);
                                                  form.append('allowance_amount' ,SingleAllowanceData.amount);
                                                  form.append('allowace_currency' ,SingleAllowanceData.currency.label);
                                                  form.append('allowance_include' ,SingleAllowanceData.allowance_include);
                                                  form.append('allowance_date' ,SingleAllowanceData.issue_date);
                                                  form.append('allowance_typer' ,SingleAllowanceData.in_salary);
                                                 
                                                  const requestOptions = {
                                                      method: "POST",
                                                      body: form,
                                                      redirect: "follow"
                                                    };
                                                    fetch(process.env.REACT_APP_LEAVE_API_URL+"/loan.php", requestOptions)
                                                      .then((response) => response.text())
                                                      .then((result) => {
                                                          setSpinner(false)
                                                          if(result== 'success'){
                                                            setSelectOrgCat([])
                                                             setIsRight(false)
                                                             fetchAllowanceFor()
                                                              Swal.fire('Success', 'You successfully update the allowance','success').then((is)=>{
                                                                if(is.isConfirmed){
                                                                    fetchAllowanceFor()
                                                                }
                                                            });
                    
                                                          }else{
                                                              Swal.fire('Server Error', 'Check your input fields are try again');
                                                          }
                                                      })
                                                      .catch((error) => console.error(error))
                                                  // api.frontforcecrm.com/loan.php
                                                }
                                                
                                              }
                                              
                                              >
                                                <Row className="mb-4">
                                                  <Label
                                                    htmlFor="horizontal-email-Input"
                                                    className="col-sm-12 col-form-label"
                                                  >
                                                    Select Employee
                                                    
                                                  </Label>
                                                  <Col sm={12}>
                                                    <Select
                                                      value={SingleAllowanceData.emp_id}

                                                      options={mockData}
                                                      
                                                      onChange={e => setSingleAllowanceData(prevState=>({
                                                        ...prevState,
                                                        emp_id : e
                                                      }))}
                                                      className="select2-selection"
                                                      placeholder="Select Employee"
                                                    />
                                                  </Col>
                                                </Row>
                                                <Row className="mb-4">
                                                  <Label
                                                    htmlFor="horizontal-email-Input"
                                                    className="col-sm-12 col-form-label"
                                                  >
                                                    Select Allowance Type
                                                  </Label>
                                                  <Col sm={12}>
                                                    <Select

                                                      options={[
                                                        {
                                                            label: "Housing Allowance",
                                                            value: "Housing Allowance"
                                                        },
                                                        {
                                                            label: "Transportation Allowance",
                                                            value: "Transportation Allowance"
                                                        },
                                                        {
                                                            label: "Medical Allowance",
                                                            value: "Medical Allowance"
                                                        },
                                                        {
                                                            label: "Meal Allowance",
                                                            value: "Meal Allowance"
                                                        },
                                                        {
                                                            label: "Education Allowance",
                                                            value: "Education Allowance"
                                                        },
                                                        {
                                                            label: "Travel Allowance",
                                                            value: "Travel Allowance"
                                                        },
                                                        {
                                                            label: "Communication Allowance",
                                                            value: "Communication Allowance"
                                                        },
                                                        {
                                                            label: "Uniform Allowance",
                                                            value: "Uniform Allowance"
                                                        },
                                                        {
                                                            label: "Entertainment Allowance",
                                                            value: "Entertainment Allowance"
                                                        },
                                                        {
                                                            label: "Special Duty Allowance",
                                                            value: "Special Duty Allowance"
                                                        },
                                                        {
                                                          label: "Fuel Allowance",
                                                          value: "Fuel Allowance"
                                                      },
                                                      {
                                                          label: "Vehicle Maintenance Allowance",
                                                          value: "Vehicle Maintenance_allowance"
                                                      },
                                                      {
                                                          label: "Internet Allowance",
                                                          value: "Internet Allowance"
                                                      },
                                                      {
                                                          label: "Cell Phone Allowance",
                                                          value: "Cell Phone Allowance"
                                                      },
                                                      {
                                                          label: "Training Allowance",
                                                          value: "Training Allowance"
                                                      }
                                                    ]}
                                                      name="allowance_type"
                                                      className="select2-selection"
                                                      placeholder="Select Employee"
                                                      value={SingleAllowanceData.allowance_type}
                                                      onChange={e => setSingleAllowanceData(prevState=>({
                                                        ...prevState,
                                                        allowance_type : e
                                                      }))}
                                                    />
                                                  </Col>
                                                </Row>
                    
                                                <Row className="mb-4">
                                                  <Label
                                                    htmlFor="horizontal-firstname-Input"
                                                    className="col-sm-12 col-form-label"
                                                  >
                                                    Enter Amount
                                                  </Label>
                                                  <Col sm={12}>
                                                    <Input
                                                      type="number"
                                                      value={SingleAllowanceData.amount}
                                                      onChange={e => setSingleAllowanceData(prevState=>({
                                                        ...prevState,
                                                        amount : e.target.value
                                                      }))}
                                                      min={1}
                                                      name="allowance_amount"
                                                      className="form-control"
                                                      id="horizontal-firstname-Input"
                                                      placeholder="Advance Amount"
                                                    />
                                                  </Col>
                                                </Row>
                                                <Row className="mb-4">
                                                  <Label
                                                    htmlFor="horizontal-firstname-Input"
                                                    className="col-sm-12 col-form-label"
                                                  >
                                                    Amount Currency
                                                  </Label>
                                                  <Col sm={12}>
                                                    <Select
                                                     value={SingleAllowanceData.currency}
                                                     onChange={e => setSingleAllowanceData(prevState=>({
                                                      ...prevState,
                                                      currency : e
                                                    }))}
                                                      options={[
                                                        {label:'PKR' , value: 'PKR'},
                                                        {label:'USD' , value: 'USD'}
                                                      ]}
                                                      name="allowance_currency"
                                                      className="select2-selection"
                                                      placeholder="Select Currency"
                                                    />
                                                  </Col>
                                                </Row>
                                                <Row className="mb-4">
                                                  <Label
                                                    htmlFor="horizontal-firstname-Input"
                                                    className="col-sm-12 col-form-label"
                                                  >
                                                    Allowance Include ?
                                                  </Label>
                                                  <Col sm={12} className="mt-2">
                                                    <Input
                                                      type="radio"
                                                      name="allowance_include"
                                                      id="horizontal-firstname-Input"
                                                      placeholder="Select Date"
                                                      onChange={(e)=>setSingleAllowanceData(prevState=>({
                                                        ...prevState,
                                                        allowance_include :e.target.value
                                                      }))}
                                                      checked={SingleAllowanceData.allowance_include == 'once' ? true  : false}
                                                      value={'once'}
                                                    /> Once
                                                    <Input
                                                      style={{marginLeft:10}}
                                                      type="radio"
                                                      name="allowance_include"
                                                      id="horizontal-firstname-Input"
                                                      placeholder="Select Date"
                                                      value={'per_month'}
                                                      onChange={(e)=>setSingleAllowanceData(prevState=>({
                                                        ...prevState,
                                                        allowance_include :e.target.value
                                                      }))}
                                                      checked={SingleAllowanceData.allowance_include == 'per_month' ? true  : false}
                                                    /> Per Month
                                                  </Col>
                                                </Row>
                                                <Row className="mb-4">
                                                  <Label
                                                    htmlFor="horizontal-firstname-Input"
                                                    className="col-sm-12 col-form-label"
                                                  >
                                                    Select Option ?
                                                  </Label>
                                                  <Col sm={12} className="mt-2">
                                                    <Input
                                                      type="radio"
                                                      name="allowance_typer"
                                                      id="horizontal-firstname-Input"
                                                      placeholder="Select Date"
                                                      onChange={(e)=>setSingleAllowanceData(prevState=>({
                                                        ...prevState,
                                                        in_salary :e.target.value
                                                      }))}
                                                      checked={SingleAllowanceData.in_salary == 'addon' ? true  : false}
                                                      value={'addon'}
                                                    /> Addon
                                                    <Input
                                                      style={{marginLeft:10}}
                                                      type="radio"
                                                      name="allowance_typerjjjjjjjjjjjjjjjjjjj"
                                                      id="horizontal-firstname-Input"
                                                      placeholder="Select Date"
                                                      value={'deduct'}
                                                      onChange={(e)=>setSingleAllowanceData(prevState=>({
                                                        ...prevState,
                                                        in_salary :e.target.value
                                                      }))}
                                                      checked={SingleAllowanceData.in_salary == 'deduct' ? true  : false}
                                                    /> Deduct
                                                  </Col>
                                                </Row>
                                                <Row className="mb-4">
                                                  <Label
                                                    htmlFor="horizontal-firstname-Input"
                                                    className="col-sm-12 col-form-label"
                                                  >
                                                    Issue Date
                                                  </Label>
                                                  <Col sm={12}>
                                                    <Input
                                                      type="date"
                                                      value={SingleAllowanceData.issue_date}
                                                      onChange={(e)=>setSingleAllowanceData(prevState=>({
                                                        ...prevState,
                                                        issue_date : e.target.value
                                                      }))}
                                                      name="allowance_issue_date"
                                                      className="form-control"
                                                      id="horizontal-firstname-Input"
                                                      placeholder="Select Date"
                                                    />
                                                  </Col>
                                                </Row>
                    
                                                
                                                {/* <Row className="mb-4">
                                                  <Label
                                                    htmlFor="horizontal-email-Input"
                                                    className="col-sm-3 col-form-label"
                                                  >
                                                    Country 
                                                  </Label>
                                                  <Col sm={9}>
                                                    <Select
                                                      value="Select"
                                                      options={optionGroup}
                                                      className="select2-selection"
                                                      placeholder="Select country or region"
                                                    />
                                                  </Col>
                                                </Row> */}
                    
                                                <Row className="float-end mb-2">
                                                  <Col>
                                                    <div>
                                                      {spinner == true ? (
                                                        <Spinner size={"md"} />
                                                      ) : (
                                                        <Button
                                                          type="submit"
                                                          color="primary"
                                                          className="w-md"
                                                        >
                                                          Edit Allowance
                                                        </Button>
                                                      )}
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </Form>:null
                                            }
                                        </OffcanvasBody>
                                    </Offcanvas>
    </React.Fragment>
  )
}

export default Loan
