import React, { Component, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { NavLink, Link, useParams } from "react-router-dom"
import { styled } from '@mui/material/styles';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Nav,
  NavItem,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  CardTitle,
  UncontrolledTooltip,
  Modal,
} from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import classnames from "classnames"
import {
  Actions,
  Coin,
  Pdate,
  Status,
  Tracked,
  Type,
} from "pages/Timesheet/SheetData"
import TableContainer from "components/Common/TableContainer"
import Select from "react-select"
import PopularPost from "pages/Dashboard-Blog/PopularPost"

import avatar4 from "../../assets/images/users/avatar-4.jpg"
import avatar5 from "../../assets/images/users/avatar-5.jpg"
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import avatar2 from "../../assets/images/users/avatar-2.jpg"
import avatar6 from "../../assets/images/users/avatar-6.jpg"
import avatar3 from "../../assets/images/users/avatar-3.jpg"
import avatar8 from "../../assets/images/users/avatar-8.jpg"
import avatar7 from "../../assets/images/users/avatar-7.jpg"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"
import ReactApexChart from "react-apexcharts"

import PieChartCustom from "pages/AllCharts/apex/PieChartCustom"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Tab,
  Tabs,
  Typography,
} from "@mui/material"
import { ArrowDropDownCircleOutlined } from "@mui/icons-material"
import { DataGrid, GridToolbar } from "@mui/x-data-grid"
import SalarySlip from "components/Common/SalarySlip";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function formatAmount(amount,currency) {
    return amount.toLocaleString('en-US', { style: 'currency', currency: currency, minimumFractionDigits: 2 });
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}
function removeBodyCss() {
    document.body.classList.add("no_padding");
}


const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));
  
  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          style={{ flexShrink: 0 }}
          width="240"
          height="200"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>No Rows</Box>
      </StyledGridOverlay>
    );
  }
 
class LastMonth extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTabValue: 0,
      orders:[],
      ordersOther:[],
      tableLoader: true,
      monthName:'',
      modal_large:false,
      singleEmployee:[],
      dataForTable:[],
      modal_largeOther:false
    }
  }
 tog_large(){
    this.setState({modal_large : true});
    removeBodyCss();
  }
 tog_largeOther(){
    this.setState({modal_largeOther : true});
    removeBodyCss();
  }
getCurrentMonthAndYear() {
    
    const currentDate = new Date();
    // Get the month and year of the previous month
    let previousMonth = currentDate.getMonth(); // Zero-based index
    let previousYear = currentDate.getFullYear();
    if (previousMonth === 0) { // If the current month is January, adjust to December of the previous year
        previousMonth = 11; // December is represented as 11
        previousYear--;
    } else {
        previousMonth--; // Decrement the month by 1 to get the previous month
    }
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ]
    const monthAbbreviation = monthNames[previousMonth]
    // Convert the month to '01' to '12' format
    const formattedMonth = (previousMonth + 1).toString().padStart(2, '0'); // Add 1 as getMonth() returns zero-based index
    const formattedYear = previousYear.toString();
    return { month: formattedMonth, year: formattedYear,monthName : monthAbbreviation };

}
HandleQuerySingle (param){
    
    this.setState({singleEmployee : [param]})

    this.tog_large();
}
fetchEmployee = async () => {
    
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let emp = localArray.map(e => e.user_id)
    let org = localArray.map(e => e.org_id)
    const {year,month,monthName} = this.getCurrentMonthAndYear()
    this.setState({monthName: monthName})
    var formdata = new FormData()
   formdata.append("method", "fetch_counter_last_payroll_list");
    formdata.append("month", month);
    formdata.append("year", year);
    formdata.append("org_id", org);
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }
    await fetch(process.env.REACT_APP_LEAVE_API_URL+"/salary.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        this.setState({dataForTable : result.response})
        
            if(result.response.length == 0 ){
            this.setState({tableLoader: false})
            }
        // onGetOrders(result)
      })
      .catch(error => console.log("error", error))
  }
  componentDidMount() {
    this.fetchEmployee()
  }
  openModalForView = (param)=>{
        console.log(param);
  
    let val  = []
    let valOther  = []
    // if(this.state.activeTabValue == 0){
        val = param.filter(e => e !== null && e.salary_currency === 'PKR');
    // } 
    // else if(this.state.activeTabValue == 1){
        valOther = param.filter(e => e !== null && e.salary_currency === 'USD');
    // } 
    this.setState({orders : val,modal_largeOther : true})
    this.setState({ordersOther : valOther})
//   }
  }
  render() {
    const columnsFor: GridColDef[] = [
        { field: 'profile_image', headerName: 'Image', width: 100, renderCell:((param)=>(<img src={param.value} className="avatar-xs rounded-circle"/>))},
        { field: 'emp_id', headerName: 'Emp ID', width: 80,renderCell:((param)=>(<p>{param.value}</p>)) },
        { field: 'first_name', headerName: 'Full Name', width: 150 ,renderCell:((param)=>(<p>{param.value} {param.row.last_name}</p>))},
        { field: 'job_title', headerName: 'Designation', width: 150 },
        { field: 'job_cat', headerName: 'Department', width: 150 },
        { field: 'joing_date', headerName: 'Hiring Date', width: 100,renderCell:((param)=>(<p>{formatDateHiring(param.value)}</p>)) },
        { field: 'basic_salary', headerName: 'Month Salary', width: 130,renderCell:((param)=>
        {
         if(param.row.schedule_off_status == 'Yes' || param.row.schedule_off_status == null){

         return (<div >
            {formatAmount(Math.round(parseFloat(param.value) - parseFloat(param.row.unpaid) + parseFloat(param.row.amount_per_month) + parseFloat(param.row.amount_per_once) - parseFloat(param.row.loan_amount) - parseFloat(param.row.month_tax) + parseFloat(param.row.month_overtime) + parseFloat(param.row.month_incentive) + parseFloat(param.row.month_bonus) + parseFloat(param.row.month_commission) - parseFloat(param.row.advance_salary) ) , param.row.salary_currency)}
            </div> )
         }else{
            return (
                <div>
                     {formatAmount(Math.round(parseFloat(param.value) - parseFloat(param.row.salary_for_this_month) - parseFloat(param.row.unpaid) + parseFloat(param.row.amount_per_month) + parseFloat(param.row.amount_per_once) - parseFloat(param.row.loan_amount) - parseFloat(param.row.month_tax) + parseFloat(param.row.month_overtime) + parseFloat(param.row.month_incentive) + parseFloat(param.row.month_bonus) + parseFloat(param.row.month_commission) - parseFloat(param.row.advance_salary))  , param.row.salary_currency)}
                </div> )
         }
        }
        ) },
        { field: '', headerName: 'Actions', width: 150,renderCell:((param)=>{
          
          
          
          
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
             
              <button 

                onClick={()=>this.HandleQuerySingle(param.row)} 
                   className="btn btn-sm btn-soft-success">
                    
                  <i className="mdi mdi-file-outline" id="viewtooltip"></i>
                </button>
              </li>
              <UncontrolledTooltip placement="top" target="viewtooltip">
                View Payroll
              </UncontrolledTooltip>
              
            </ul>
          
          )
        }) }
        ];
    const columnsForMain: GridColDef[] = [
        { field: 'month_name', headerName: 'Image', width: 280, renderCell:((param)=>(<p>{param.value} -  {param.row.month_year}</p>))},
        { field: 'month_year', headerName: 'Total IN USD', width: 280,renderCell:((param)=>{
            
            let amountPKR= 0;
            let amountUSD = 0;
            param.row.response.map((e,index)=>{
                if(e.schedule_off_status == 'Yes'){
                    if(e.salary_currency == 'PKR' ){
                        amountPKR = amountPKR + parseFloat(e.basic_salary) - parseFloat(e.unpaid) + parseFloat(e.amount_per_month) + parseFloat(e.amount_per_once) - parseFloat(e.loan_amount) - parseFloat(e.month_tax) + parseFloat(e.month_overtime) + parseFloat(e.month_incentive) + parseFloat(e.month_bonus) + parseFloat(e.month_commission) - parseFloat(e.advance_salary);
                      }else if(e.salary_currency  == 'USD'){
                        amountUSD = amountUSD + parseFloat(e.basic_salary) - parseFloat(e.unpaid) + parseFloat(e.amount_per_month) + parseFloat(e.amount_per_once) - parseFloat(e.loan_amount) - parseFloat(e.month_tax) + parseFloat(e.month_overtime) + parseFloat(e.month_incentive) + parseFloat(e.month_bonus) + parseFloat(e.month_commission) - parseFloat(e.advance_salary);
                    }
                  }else{
                      if(e.salary_currency == 'PKR' ){
                        amountPKR = amountPKR + parseFloat(e.basic_salary) - parseFloat(e.salary_for_this_month)  - parseFloat(e.unpaid) + parseFloat(e.amount_per_month) + parseFloat(e.amount_per_once) - parseFloat(e.loan_amount) - parseFloat(e.month_tax) + parseFloat(e.month_overtime) + parseFloat(e.month_incentive) + parseFloat(e.month_bonus) + parseFloat(e.month_commission) - parseFloat(e.advance_salary);
                      }else if(e.salary_currency  == 'USD'){
                        amountUSD = amountUSD + parseFloat(e.basic_salary) - parseFloat(e.salary_for_this_month) -  parseFloat(e.unpaid) + parseFloat(e.amount_per_month) + parseFloat(e.amount_per_once) - parseFloat(e.loan_amount) - parseFloat(e.month_tax) + parseFloat(e.month_overtime) + parseFloat(e.month_incentive) + parseFloat(e.month_bonus) + parseFloat(e.month_commission) - parseFloat(e.advance_salary);
                    }
                  }
                  
                })
                return <p >{formatAmount(parseFloat(amountUSD), 'USD')}</p>;
        })},
        { field: 'id', headerName: 'Total IN PKR', width: 280 ,renderCell:((param)=>{
            let amountPKR= 0;
            let amountUSD = 0;
            param.row.response.map((e,index)=>{

                if(e.schedule_off_status == 'Yes'){
                    if(e.salary_currency == 'PKR' ){
                        amountPKR = amountPKR + parseFloat(e.basic_salary) - parseFloat(e.unpaid) + parseFloat(e.amount_per_month) + parseFloat(e.amount_per_once) - parseFloat(e.loan_amount) - parseFloat(e.month_tax) + parseFloat(e.month_overtime) + parseFloat(e.month_incentive) + parseFloat(e.month_bonus) + parseFloat(e.month_commission) - parseFloat(e.advance_salary);
                      }else if(e.salary_currency  == 'USD'){
                        amountUSD = amountUSD + parseFloat(e.basic_salary) - parseFloat(e.unpaid) + parseFloat(e.amount_per_month) + parseFloat(e.amount_per_once) - parseFloat(e.loan_amount) - parseFloat(e.month_tax) + parseFloat(e.month_overtime) + parseFloat(e.month_incentive) + parseFloat(e.month_bonus) + parseFloat(e.month_commission) - parseFloat(e.advance_salary);
                    }
                  }else{
                      if(e.salary_currency == 'PKR' ){
                        amountPKR = amountPKR + parseFloat(e.basic_salary) - parseFloat(e.salary_for_this_month)  - parseFloat(e.unpaid) + parseFloat(e.amount_per_month) + parseFloat(e.amount_per_once) - parseFloat(e.loan_amount) - parseFloat(e.month_tax) + parseFloat(e.month_overtime) + parseFloat(e.month_incentive) + parseFloat(e.month_bonus) + parseFloat(e.month_commission) - parseFloat(e.advance_salary);
                      }else if(e.salary_currency  == 'USD'){
                        amountUSD = amountUSD + parseFloat(e.basic_salary) - parseFloat(e.salary_for_this_month) -  parseFloat(e.unpaid) + parseFloat(e.amount_per_month) + parseFloat(e.amount_per_once) - parseFloat(e.loan_amount) - parseFloat(e.month_tax) + parseFloat(e.month_overtime) + parseFloat(e.month_incentive) + parseFloat(e.month_bonus) + parseFloat(e.month_commission) - parseFloat(e.advance_salary);
                    }
                  }
                  
                })
                return <p>{formatAmount(amountPKR, 'PKR')}</p>;
        })},
        { field: '', headerName: 'Actions', width: 150,renderCell:((param)=>{
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
              <button 
                onClick={()=>this.openModalForView(param.row.response)} 
                   className="btn btn-sm btn-soft-success">
                    
                  <i className="mdi mdi-file-outline" id="viewtooltip"></i>
                </button>
              </li>
              <UncontrolledTooltip placement="top" target="viewtooltip">
                View Payroll
              </UncontrolledTooltip>
              
            </ul>
          
          )
        }) }
        ];
    document.title = `Salary Paid | ${process.env.REACT_APP_NAME}`
    const {
      activeTabValue,
      ordersOther,
      modal_largeOther,
      dataForTable,
      orders,
      tableLoader,
      monthName,
      modal_large,
      singleEmployee
    } = this.state
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        this.setState({ activeTabValue: newValue })
        this.fetchEmployee()
    }
    function formatDateHiring(inputDate) {
        // Split the input date string into month, day, and year parts
        var parts = inputDate.split('/');
        var month = parseInt(parts[0], 10);
        var day = parseInt(parts[1], 10);
        var year = parseInt(parts[2], 10);
    
        // Create a Date object using the parsed values
        var date = new Date(year, month - 1, day); // Subtracting 1 from month since it's zero-indexed
    
        // Get the day and month names
        var dayName = date.getDate();
        var monthName = date.toLocaleString('default', { month: 'short' });
    
        // Format the date as 'day MonthName year'
        var formattedDate = dayName + ' ' + monthName + ' ' + year;
        
        return formattedDate;
    }
    
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <div className="ms-auto">
               <Row>
                        <Col md={12}>
                            
                        <Box sx={{ width: '100%' }}>
                          
                          <DataGrid
                                        
                          autoHeight
                          sx={{ '--DataGrid-overlayHeight': '300px' }}
                          slots={{
                           toolbar:  GridToolbar ,
                           noRowsOverlay: CustomNoRowsOverlay 
                           
                         }}
                         rows={dataForTable} 
                         loading = { dataForTable.length == 0 && tableLoader == true ? true : false}
                         columns={columnsForMain}
                         getRowId={(row)=>row.id}
                          />
                       
                         </Box>
                        </Col>
                     </Row>
            </div>
          </Container>
         
        </div>
        <Modal
                      size="lg"
                      isOpen={modal_large}
                      backdrop='static'
                      toggle={() => {
                        this.tog_largeOther();
                      }}
                    >
                      <div className="modal-header">
                        <h5
                          className="modal-title mt-0"
                          id="myLargeModalLabel"
                        >
                          {
                            singleEmployee.map((e)=>(
                                e.first_name +' '+ e.last_name +' ' +'Salary'
                            ))
                          }
                        </h5>
                        <button
                          onClick={() => {
                            this.setState({modal_large : false});
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      {this.state.singleEmployee.length == 0 ? null :   <SalarySlip monthFor={monthName} yearFor={new Date().getFullYear()} employee={this.state.singleEmployee}/> }
                      </div>
                    </Modal>
                    <Modal
                      size="xl"
                      isOpen={modal_largeOther}
                      backdrop='static'
                      toggle={() => {
                        this.tog_largeOther();
                      }}
                    >
                      <div className="modal-header">
                      <h5
                          className="modal-title mt-0"
                          id="myLargeModalLabel"
                        >
                         Report 
                        </h5>
                        <button
                          onClick={() => {
                            this.setState({modal_largeOther : false});
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                      <div className="modal-body">
                      <Row>
                <Col md={12}>
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs value={activeTabValue} onChange={handleChange}>
                        <Tab label="IN PKR" {...a11yProps(0)} />
                        <Tab  label="IN USD" {...a11yProps(1)} /> : null

                       
                      </Tabs>
                    </Box>
                    
                    <CustomTabPanel value={activeTabValue} index={0}>
                     <Row>
                        <Col md={12}>
                        <Box sx={{ width: '100%' }}>
                          
                          <DataGrid
                                        
                          autoHeight
                          sx={{ '--DataGrid-overlayHeight': '300px' }}
                          slots={{
                           toolbar:  GridToolbar ,
                           noRowsOverlay: CustomNoRowsOverlay 
                           
                         }}
                         rows={orders} 
                        //  loading = { orders.length == 0 && tableLoader == true ? true : false}
                         columns={columnsFor}
                         getRowId={(row)=>row.emp_id}
                          />
                       
                         </Box>
                        </Col>
                     </Row>
                    </CustomTabPanel>
                    <CustomTabPanel value={activeTabValue} index={1}>
                     <Row>
                        <Col md={12}>
                        <Box sx={{ width: '100%' }}>
                          
                          <DataGrid
                                        
                          autoHeight
                          sx={{ '--DataGrid-overlayHeight': '300px' }}
                          slots={{
                           toolbar:  GridToolbar ,
                           noRowsOverlay: CustomNoRowsOverlay 
                           
                         }}
                         rows={ordersOther} 
                        //  loading = { ordersOther.length == 0 && tableLoader == true ? true : false}
                         columns={columnsFor}
                         getRowId={(row)=>row.emp_id}
                          />
                       
                         </Box>
                        </Col>
                     </Row>
                     
                    </CustomTabPanel>
                  </Box>
                </Col>
              </Row>
                        </div>
                        </Modal>
      </React.Fragment>
    )
  }
}
LastMonth.PropTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

const mapStateToProps = ({ crypto }) => ({
  orders: crypto.orders,
})

const mapDispatchToProps = dispatch => ({
  onGetOrders: () => dispatch(getCryptoOrders()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LastMonth))
