import React, { useEffect, useState, useMemo } from "react"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  CardTitle,
  Row,
} from "reactstrap"
import img3 from "../../assets/images/integration-logo-teams.svg"
import img2 from "../../assets/images/slack-logo.5f5040f6.svg"
import img4 from "../../assets/images/integration-logo-zapier.svg"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

const Integration = props => {
  //meta title
  document.title = "Timeoff | Skote - React Admin & Dashboard Template"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <h5 className="card-title mb-2 font-size-22">
            Connect Track Presence with your workflow
          </h5>
          <div className="text-muted mb-4 font-size-14">
            Track time and sync hours to your favourite business tools.
          </div>
          <Row>
            <Col xl={4}>
              <Card style={{ minHeight: "100px" }}>
                <CardBody>
                  <Row>
                    <Col xl={3}>
                      <div className="mb-2">
                        <img
                          className="rounded-circle avatar-sm"
                          src={img2}
                          alt=""
                        />
                      </div>
                    </Col>
                    <Col xl={8}>
                      <CardTitle>Slack</CardTitle>
                      <h5 className="card-title font-size-10">Communication</h5>
                      <div className="text-muted mb-3">
                        Check in and out, check your hours, see who's working
                        and more.
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4}>
              <Card style={{ minHeight: "100px" }}>
                <CardBody>
                  <Row>
                    <Col xl={3}>
                      <div className="mb-2">
                        <img
                          className="rounded-circle avatar-sm"
                          src={img4}
                          alt=""
                        />
                      </div>
                    </Col>
                    <Col xl={8}>
                      <CardTitle>Zapier</CardTitle>
                      <h5 className="card-title font-size-10">Productivity</h5>
                      <div className="text-muted mb-3">
                        Check in and out, check your hours, see who's working
                        and more.
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4}>
              <Card style={{ minHeight: "100px" }}>
                <CardBody>
                  <Row>
                    <Col xl={3}>
                      <div className="mb-2">
                        <img
                          className="rounded-circle avatar-sm"
                          src={img3}
                          alt=""
                        />
                      </div>
                    </Col>
                    <Col xl={8}>
                      <CardTitle>Microsoft Teams</CardTitle>
                      <h5 className="card-title font-size-10">communication</h5>
                      <div className="text-muted mb-3">
                        Check in and out, check your hours, see who's working
                        and more.
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Integration
