import React, { Component, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Badge, Card, Col, Container, Row } from "reactstrap"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
const SkeletonCard = ({ length }) => {
  for (let i = 0; i <= length; i++) {
    return (
      <Col md={12}>
        <Card style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 10 }}>
          <Row>
            <Col md={12}>
              <Row>
                <Col md={1}>
                  <Skeleton circle height={50} width={50} />
                </Col>

                <Col md={4}>
                  <Skeleton height={20} width={100} />
                  <Skeleton height={20} width={80} />
                </Col>
                <Col md={7}>
                  <p style={{ textAlign: "end",paddingRight:10 }}>
                    <Skeleton height={20} width={80} />
                  </p>
                  <p style={{ textAlign: "end" ,paddingRight:10}}>
                    <Skeleton height={20} width={100} />
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
    )
  }
}

const TimeSheetDetail = () => {
  document.title = `Member Detail | ${process.env.REACT_APP_NAME}`
  const { state } = useLocation()
  console.log(state);
  const [routeinData, setrouteinData] = useState([])
  const [firstIn, setFirstIn] = useState("")
  const [lastOut, setLastOut] = useState("")
  const [totalWorking, settotalWorking] = useState("")
  const [totalBreak, setTotalBreak] = useState("")
  const [shownSkeleton, setShownSkeleton] = useState(false)
  const [showHolder, setshowHolder] = useState(false)
  console.log(state)
  // const { myParamObject } = this.props.location.state || {};
  console.log(state)
  const convertDateFormat = inputDate => {
    // Create a Date object from the input date string
    const dateObj = new Date(inputDate)

    // Format the date as desired
    const formattedDate = dateObj.toLocaleString("en-US", {
      weekday: "short",
      day: "numeric",
      month: "short",
    })

    return formattedDate
  }
  const fetchEmployeeRoutein = (id, created_at) => {
    setShownSkeleton(true)
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let org = localArray.map(e => e.org_id)
    var formdata = new FormData()

    formdata.append("method", "get-routein-by-id")
    formdata.append("emp_id", created_at)
    formdata.append("org_id", org)
    formdata.append("created_at", id)

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    fetch(process.env.REACT_APP_LEAVE_API_URL+"/activity.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        setrouteinData(result)
        setShownSkeleton(false)
        if(result.length == 0 || routeinData.length == 0){
          setshowHolder(false)
        }
      })
      .catch(error => {
        console.log("error", error)
        setShownSkeleton(false)
    })
  }
  const convertTime = inputTime => {
    if (inputTime !== 0) {
      const [hours, minutes, seconds] = inputTime.split(":")
      const dateObj = new Date()
      dateObj.setHours(hours)
      dateObj.setMinutes(minutes)
      dateObj.setSeconds(seconds)
      const formattedTime = dateObj.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })

      return formattedTime.toLowerCase() // Convert to lowercase for consistency with 'pm'
    } else {
      return "N/a"
    }
  }

  const formatTime = seconds => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60
    return `${String(hours).padStart(2, "0")}h:${String(minutes).padStart(
      2,
      "0"
    )}m:${String(remainingSeconds).padStart(2, "0")}`
  }
  useEffect(() => {
    fetchEmployeeRoutein(state.created_at, state.obj.id)
    let val = state.obj.total_work_time
    let val1 = state.obj.total_break_time
    settotalWorking(formatTime(val))
    setTotalBreak(formatTime(val1))
    let value = []
    if (state.obj.time_punch_in !== null) {
      value = state.obj.time_punch_in.split(" ")
    }
    let valuez = []
    if (state.obj.time_punch_out !== null) {
      valuez = state.obj.time_punch_out.split(" ")
    }
    setFirstIn(convertTime(value.length !== 0 ? value[1] : 0))
    setLastOut(convertTime(valuez.length !== 0 ? valuez[1] : 0))
  }, [])
  const SkeletonTrackedTimeCard = () => (
    <Col md={12}>
      <Card style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 10 }}>
        <Row>
          <Col md={12}>
            <Skeleton height={20} width={150} style={{ marginBottom: 10 }} />
            <Skeleton height={20} width={100} />
          </Col>
          <Row>
            <Col md={6}>
              <Skeleton height={20} width={100} />
              <Skeleton height={20} width={80} />
            </Col>
            <Col md={6}>
              <Skeleton height={20} width={100} />
              <Skeleton height={20} width={80} />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Skeleton height={20} width={100} />
              <Skeleton height={20} width={80} />
            </Col>
            <Col md={6}>
              <Skeleton height={20} width={100} />
              <Skeleton height={20} width={80} />
            </Col>
          </Row>
        </Row>
      </Card>
    </Col>
  );
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md={12}>

                {
                    shownSkeleton == true ? 
                    <SkeletonTrackedTimeCard/>
                    :
                    <Card
                        style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 10 }}
                    >
                        <Row>
                        <Col md={12}>
                            <p style={{ fontSize: 18, fontWeight: "600" }}>
                            Tracked Time
                            </p>
                            <p>{convertDateFormat(state.obj.created_at)}</p>
                        </Col>
                        {
                          state.obj.status == 'leave' ? 
                          <Row>
                            <Col md={12}>
                              <p>On Leave </p>
                            </Col>
                            </Row>:
                            <div>
                        <Row>
                            <Col md={6}>
                            <p style={{ fontWeight: "600" }}>First In</p>
                            <p>{firstIn}</p>
                            </Col>
                            <Col md={6}>
                            <p style={{ fontWeight: "600" }}>Last out</p>
                            <p>{lastOut}</p>
                            </Col>
                        </Row>
                        <Row>
                        <Col md={6}>
                        <p style={{ fontWeight: "600" }}>Break Hours</p>
                        <p>{totalBreak}</p>
                        </Col>
                        <Col md={6}>
                        <p style={{ fontWeight: "600" }}>Working Hours</p>
                        <p>{totalWorking}</p>
                        </Col>
                    </Row>
                    </div>
                        }
                        
                        </Row>
                    </Card>
                }
            </Col>
            {routeinData.length == 0  && showHolder == true ? <SkeletonCard length={3} /> : null}
            {routeinData.map((e, index) => (
               <Card
                key={index}
                style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 10 }}
              >
                <Row>
                  <Col md={12}>
                    <Row>
                      <Col md={1}>
                        <img
                          src={state.obj.data.profile_image}
                          style={{ width: 50, height: 50, borderRadius: 100 }}
                        />
                      </Col>

                      <Col md={4}>
                        {convertTime(e.data_routein.activity_time)}
                        <p>{formatTime(e.data_routein.no_seconds)}</p>
                      </Col>
                      <Col md={7}>
                        <p style={{ textAlign: "end" }}>
                          {e.data_routein.activity_type == "Break Time" ? (
                            <Badge className="bg-warning font-size-12">
                              Break
                            </Badge>
                          ) : (
                            <Badge className="bg-success font-size-12">
                              Working
                            </Badge>
                          )}
                        </p>
                        <p style={{ textAlign: "end" }}>
                          {e.data_routein.other_type === "Tasks" ? (
                            <Badge className="bg-info font-size-12">
                              {e.activity_data.task_title}
                            </Badge>
                          ) : (
                            <Badge className="font-size-12">
                              {e.activity_data.activity_name}
                            </Badge>
                          )}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default TimeSheetDetail
